/*==============================================================================
// File:        _text.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared text component - SASS
//============================================================================*/

.zen-text {
  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("lead") {
    @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 17px, 19px);
  }

  @include modifier("subtitle") {
    @extend h4;

    color: var(--rk-text);
  }

  @include modifier("default") {
    color: var(--rk-default);
  }

  @include modifier("default-bold") {
    line-height: var(--rk-line-height-df);
    font-weight: bold;
    color: var(--rk-text);
  }

  @include modifier("default-dark") {
    color: var(--rk-default-dark);
  }

  @include modifier("primary") {
    color: var(--rk-primary);
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);
  }

  @include modifier("light") {
    color: var(--rk-base-white);
  }

  @include modifier("light-faded") {
    color: var(--rk-base-white);
    opacity: .4;
  }

  @include modifier("light-bold") {
    color: var(--rk-base-white);
    font-weight: bold;
  }

  @include modifier("large") {
    @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 15px, 17px);

    line-height: var(--rk-line-height-xl);
  }

  @include modifier("large-bold") {
    @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 15px, 17px);

    line-height: var(--rk-line-height-sm);
    font-weight: bold;
  }

  @include modifier("availability") {
    font-size: var(--rk-font-size-md);
    vertical-align: middle;
    display: inline-block;
    color: var(--rk-secondary);
    padding: 0;
    margin: 0;

    @include adjoin("active") {
      font-weight: bold;
      color: var(--rk-primary);
    }
  }

  @include modifier("alert") {
    color: var(--rk-danger);
  }

  @include modifier("author") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 16px, 26px);

    line-height: var(--rk-line-height-df);
    color: var(--rk-default);
  }

  @include modifier("price") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 22px, 30px);

    letter-spacing: var(--rk-letter-space-lg);
    font-family: var(--rk-secondary-font);
    color: var(--rk-primary);
  }

  /*------------------------------------------------------------------------------
  // Font Size Changes
  //----------------------------------------------------------------------------*/

  @include modifier("text-df") {
    font-size: var(--rk-font-size-df);
  }

  @include modifier("text-xxl") {
    font-size: var(--rk-font-size-xxl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }

  @include modifier("text-h1") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 38px, 48px);

    letter-spacing: var(--rk-letter-space-lg);
    font-family: var(--rk-secondary-font);
  }

  @include modifier("text-h2") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 32px, 42px);

    letter-spacing: var(--rk-letter-space-lg);
    font-family: var(--rk-secondary-font);
  }

  @include modifier("text-h3") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 26px, 32px);

    letter-spacing: var(--rk-letter-space-lg);
    font-family: var(--rk-secondary-font);
  }

  @include modifier("text-h4") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 22px, 26px);

    letter-spacing: var(--rk-letter-space-lg);
    font-family: var(--rk-secondary-font);
  }

  @include modifier("text-h5") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 20px, 22px);

    letter-spacing: var(--rk-letter-space-lg);
    font-family: var(--rk-secondary-font);
  }

  @include modifier("text-h6") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 17px, 19px);

    letter-spacing: var(--rk-letter-space-lg);
    font-family: var(--rk-secondary-font);
  }

  /*------------------------------------------------------------------------------
  // Font Family Changes
  //----------------------------------------------------------------------------*/

  @include modifier("font-secondary") {
    font-family: var(--rk-secondary-font);
  }
}

/*------------------------------------------------------------------------------
// Extend text element & modifiers
//----------------------------------------------------------------------------*/

%zen-text {
  @extend .zen-text;
}

%zen-text--lead {
  @extend .zen-text--lead;
}

%zen-text--font-secondary {
  @extend .zen-text--font-secondary;
}
