.zen-experience {
  @include element("header") {
    padding: 70px 0;
    background: var(--rk-primary);
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      max-width: 960px;
      margin: 0 auto;
    }
  }

  @include element("body") {
    max-width: 1200px;
    margin: 0 auto;
  }

  @include element("content-blocks") {
    background: #F9F8F5;
    padding: 100px 0;

    .content-block-card {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      flex-direction: column;
    }

    .card-img-top {
      width: 100px;
      margin: 0 auto;
    }
  }
}
