/*==============================================================================
// File:        _gallery.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared gallery component - SASS
//============================================================================*/

.zen-gallery {
  margin-top: 120px;
  margin-bottom: 120px;

  .carousel-indicators {
    bottom: -30px;
    right: 50px;
    margin: 0;
    justify-content: flex-end;
  }

  .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 50vw;
    border-top: 0;
    border-bottom: 0;
  }

  .carousel-controls {
    position: absolute;
    bottom: -70px;
    left: 50px;
    margin: 0;
    justify-content: flex-start;
    display: flex;
    gap: 10px;

    @media (max-width: 500px) {
      left: 0;
      right: 0;
      justify-content: center;
    }
  }

  @media (max-width: var(--rk-viewport-md)) {
    .carousel-controls {
      display: none;
    }

    .carousel-indicators {
      justify-content: center;
      right: 0;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: block;
    position: relative;
    width: 60px;
  }

  .carousel-item .caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 18px;
    padding: 80px 20px 20px;
    background: linear-gradient(0deg, rgba(var(--rk-base-black), .3), rgba(var(--rk-base-black), 0) 100%);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("large") {
    margin: var(--rk-m-xl) auto;
    max-width: 100%;
    border: none;
  }

  @include modifier("fade-transition") {
    .carousel-item {
      transition-property: opacity;
      transition-duration: .9s;
      opacity: 0;
    }

    .carousel-item-prev.carousel-item-right,
    .carousel-item-next.carousel-item-left,
    .carousel-item.active {
      opacity: 1;
    }

    .active.carousel-item-right,
    .active.carousel-item-left {
      opacity: 0;
    }

    .active.carousel-item-left,
    .active.carousel-item-prev,
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transform: translate3d(0, 0, 0);
      transform: translateX(0);
    }
  }

  @include modifier("hotel") {
    margin: 0;

    .carousel-inner {
      max-height: 700px;
    }

    .carousel-indicators {
      @media (max-width: 500px) {
        display: none;
      }
    }
  }
}
