/*==============================================================================
// File:        _fonts.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main web font references - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Web fonts - Google Font "Open Sans" & custome "Albertus MT Std"
//----------------------------------------------------------------------------*/

@font-face {
  font-family: "Playfair Display";
  src: url("../fonts/PlayfairDisplay-Bold.eot");
  src:
    url("../fonts/PlayfairDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/PlayfairDisplay-Bold.woff2") format("woff2"),
    url("../fonts/PlayfairDisplay-Bold.woff") format("woff"),
    url("../fonts/PlayfairDisplay-Bold.ttf") format("truetype"),
    url("../fonts/PlayfairDisplay-Bold.svg#PlayfairDisplay-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display";
  src: url("../fonts/PlayfairDisplay-Black.eot");
  src:
    url("../fonts/PlayfairDisplay-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/PlayfairDisplay-Black.woff2") format("woff2"),
    url("../fonts/PlayfairDisplay-Black.woff") format("woff"),
    url("../fonts/PlayfairDisplay-Black.ttf") format("truetype"),
    url("../fonts/PlayfairDisplay-Black.svg#PlayfairDisplay-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Galins Regular";
  src: url("../fonts/galins-regular-webfont.ttf") format("truetype");
  src:
    url("../fonts/galins-regular-webfont.woff2") format("woff2"),
    url("../fonts/galins-regular-webfont.ttf") format("truetype"),
    url("../fonts/galins-regular-webfont.svg#galinsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: HypatiaSansPro;
  src: url("../fonts/HypatiaSansPro-Regular.eot");
  src:
    url("../fonts/HypatiaSansPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HypatiaSansPro-Regular.woff") format("woff"),
    url("../fonts/HypatiaSansPro-Regular.ttf") format("truetype"),
    url("../fonts/HypatiaSansPro-Regular.svg#HypatiaSansPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HypatiaSansPro;
  src: url("../fonts/HypatiaSansPro-Black.eot");
  src:
    url("../fonts/HypatiaSansPro-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HypatiaSansPro-Black.woff") format("woff"),
    url("../fonts/HypatiaSansPro-Black.ttf") format("truetype"),
    url("../fonts/HypatiaSansPro-Black.svg#HypatiaSansPro-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HypatiaSansPro;
  src: url("../fonts/HypatiaSansPro-Bold.eot");
  src:
    url("../fonts/HypatiaSansPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/HypatiaSansPro-Bold.woff") format("woff"),
    url("../fonts/HypatiaSansPro-Bold.ttf") format("truetype"),
    url("../fonts/HypatiaSansPro-Bold.svg#HypatiaSansPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*------------------------------------------------------------------------------
// ZEN Icons - Source: "http://fontello.com/"
//----------------------------------------------------------------------------*/

@font-face {
  font-family: fontello;
  src: url("../fonts/fontello.eot");
  src:
    url("../fonts/fontello.eot#iefix") format("embedded-opentype"),
    url("../fonts/fontello.woff") format("woff"),
    url("../fonts/fontello.ttf") format("truetype"),
    url("../fonts/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

.fontello {
  /*Use important to prevent issues*/
  font-family: fontello, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  speak: none;
}

.icon-arrow-down:before {
  content: "\e800";
}

.icon-arrow-left:before {
  content: "\e801";
}

.icon-arrow-right:before {
  content: "\e802";
}

.icon-arrow-up:before {
  content: "\e803";
}

.icon-caret-down:before {
  content: "\e804";
}

.icon-caret-left:before {
  content: "\e805";
}

.icon-caret-right:before {
  content: "\e806";
}

.icon-caret-up:before {
  content: "\e807";
}

.icon-facebook:before {
  content: "\e808";
}

.icon-pintrest:before {
  content: "\e80a";
}

.icon-snapchat:before {
  content: "\e80b";
}

.icon-telephone:before {
  content: "\e80c";
}

.icon-twitter:before {
  content: "\e80d";
}

.icon-whatsapp:before {
  content: "\e80e";
}

.icon-youtube:before {
  content: "\e80f";
}

.icon-sys-close:before {
  content: "\e810";
}

.icon-sys-hamburger:before {
  content: "\e811";
}

.icon-sys-heart-solid:before {
  content: "\e812";
}

.icon-sys-heart:before {
  content: "\e813";
}

.icon-sys-plus:before {
  content: "\e814";
}

.icon-sys-tick:before {
  content: "\e815";
}

.icon-chevron-down-thin:before {
  content: "\e816";
}

.icon-chevron-down:before {
  content: "\e817";
}

.icon-chevron-left-thin:before {
  content: "\e818";
}

.icon-chevron-left:before {
  content: "\e819";
}

.icon-chevron-right-thin:before {
  content: "\e81a";
}

.icon-chevron-right:before {
  content: "\e81b";
}

.icon-chevron-up-thin:before {
  content: "\e81c";
}

.icon-chevron-up:before {
  content: "\e81d";
}

.icon-double-chevron-left:before {
  content: "\e81e";
}

.icon-double-chevron-right:before {
  content: "\e81f";
}

.icon-arrow-right-alt:before {
  content: "\e820";
}

.icon-calendar:before {
  content: "\e821";
}

.icon-door:before {
  content: "\e822";
}

.icon-food-alt:before {
  content: "\e823";
}

.icon-food:before {
  content: "\e824";
}

.icon-highlight:before {
  content: "\e825";
}

.icon-drink:before {
  content: "\e826";
}

.icon-info:before {
  content: "\e827";
}

.icon-location-pin:before {
  content: "\e828";
}

.icon-logo:before {
  content: "\e829";
}

.icon-people:before {
  content: "\e82a";
}

.icon-comma-end:before {
  content: "\e82c";
}

.icon-comma-start:before {
  content: "\e82d";
}

.icon-rain:before {
  content: "\e82e";
}

.icon-sun:before {
  content: "\e82f";
}

.icon-temperature:before {
  content: "\e830";
}

.icon-transfer:before {
  content: "\e831";
}

.icon-waves:before {
  content: "\e832";
}

.icon-price:before {
  content: "\e833";
}

.icon-bed:before {
  content: "\e834";
}

.icon-bath:before {
  content: "\e82b";
}

.icon-gym:before {
  content: "\e835";
}

.icon-helipad:before {
  content: "\e836";
}

.icon-indoor-sports:before {
  content: "\e837";
}

.icon-scuba-diving:before {
  content: "\e838";
}

.icon-padel-court:before {
  content: "\e839";
}

.icon-spa:before {
  content: "\e83a";
}

.icon-tennis-court:before {
  content: "\e83b";
}

.icon-submarine:before {
  content: "\e83c";
}

.icon-water-sports:before {
  content: "\e83d";
}

.icon-wine-cellar:before {
  content: "\e83e";
}

.icon-yacht:before {
  content: "\e83f";
}

.icon-yoga:before {
  content: "\e840";
}

.icon-laundry-service:before {
  content: "\e841";
}

.icon-kids-club:before {
  content: "\e842";
}

.icon-surfing:before {
  content: "\e843";
}

.icon-teens-club:before {
  content: "\e844";
}

.icon-twitter-x:before {
  content: "\e845";
}

.icon-reef:before {
  content: "\e846";
}

.icon-island:before {
  content: "\e847";
}

.icon-instagram:before {
  content: "\f16d";
}

.icon-golf-facilities:before {
  content: "\f236";
}

.icon-conference:before {
  content: "\f2cd";
}

.icon-linkedin:before {
  content: "\f30c";
}

.icon-star-empty:before {
  content: "\e848";
}

.icon-star:before {
  content: "\e809";
}

.icon-download-cloud:before {
  content: "\f0ed";
}

/*------------------------------------------------------------------------------
// Font Awesome Overrides
//----------------------------------------------------------------------------*/
.fa {
  &-long-arrow-left {
    &:before {
      content: "\e801";
    }
  }

  &-long-arrow-right {
    &:before {
      content: "\e802";
    }
  }
}

/*------------------------------------------------------------------------------
// Extend Icon Class
//----------------------------------------------------------------------------*/

%fontello {
  @extend .fontello;
}
