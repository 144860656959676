/*==============================================================================
// File:        _tab.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared tab component - SASS
//============================================================================*/

.zen-tab {
  border: 0;
  border-bottom: 2px solid var(--rk-secondary);
  padding: 40px;
  background: var(--rk-base-white);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("heading-item") {
    display: inline-block;

    @include media-breakpoint-down(sm) {
      width: 50%;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  @include element("content-pane") {
    padding: var(--rk-div-p-xl) 0;
    display: none;

    @include modifier("active") {
      display: block;
    }

    @include modifier("no-pad") {
      padding: 0;
    }
  }

  @include element("link") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 13px, 17px, true);

    padding: 8px 30px 8px 10px;
    text-transform: uppercase;
    position: relative;
    margin: 0 2px 0 0;
    display: block;
    color: var(--rk-text);

    @include media-breakpoint-only(lg) {
      @include fluid-prop(font-size, $viewport-md, $viewport-lg, 12px, 15px, true);

      padding: 8px 26px 8px 7px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      background: var(--rk-primary);
      color: var(--rk-base-white);
    }

    @include modifier("active") {
      background: var(--rk-primary);
      color: var(--rk-base-white);
    }

    @include modifier("current") {
      background: var(--rk-primary);
      color: var(--rk-base-white);
    }
  }

  .active,
  .current {
    text-decoration: none;
    background: var(--rk-primary);
    color: var(--rk-base-white);
  }
}

/*------------------------------------------------------------------------------
// Extend tab element & modifiers
//----------------------------------------------------------------------------*/

%zen-tab {
  @extend .zen-tab;
}

%zen-tab__heading-item {
  @extend .zen-tab__heading-item;
}

%zen-tab__link {
  @extend .zen-tab__link;
}

%zen-tab__link--active {
  @extend .zen-tab__link--active;
}

%zen-tab__link--current {
  @extend .zen-tab__link--current;
}
