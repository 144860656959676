/*==============================================================================
// File:        _type.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main type styling - SASS
//============================================================================*/

h1 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 38px, 48px);

  font-family: var(--rk-primary-font);

  strong,
  b {
    font-weight: 700;
  }
}

h2 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 32px, 42px);

  font-family: var(--rk-primary-font);

  strong,
  b {
    font-weight: 700;
  }
}

h3 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 26px, 32px);

  font-family: var(--rk-secondary-font);
  color: var(--rk-primary);
  font-weight: 700;

  strong,
  b {
    color: var(--rk-secondary);
    font-weight: 900;
  }
}

h4 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 22px, 26px);

  font-family: var(--rk-secondary-font);
  color: var(--rk-primary);
  font-weight: 700;

  strong,
  b {
    color: var(--rk-secondary);
    font-weight: 900;
  }
}

h5 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 20px, 22px);

  font-family: var(--rk-secondary-font);
  color: var(--rk-primary);
  font-weight: 700;

  strong,
  b {
    color: var(--rk-secondary);
    font-weight: 900;
  }
}

h6 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 17px, 19px);

  font-family: var(--rk-secondary-font);
  color: var(--rk-primary);
  font-weight: 700;

  strong,
  b {
    color: var(--rk-secondary);
    font-weight: 900;
  }
}

p {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 15px, 17px);

  font-family: var(--rk-secondary-font);
  line-height: 1.8;
}

div,
span,
table,
td,
tr,
th,
tbody,
thead,
tfoot,
ul,
ol,
li,
blockquote {
  font-family: var(--rk-secondary-font);
}

// Extend Heading Tags
%h1 {
  @extend h1;
}

%h2 {
  @extend h2;
}

%h3 {
  @extend h3;
}

%h4 {
  @extend h4;
}

%h5 {
  @extend h5;
}

%h6 {
  @extend h6;
}

%p {
  @extend p;
}
