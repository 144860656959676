.zen-divider {
  height: 5px;
  opacity: 1;
  width: 100px;
  margin: 30px auto;
  border-top: none;

  @include modifier("full") {
    width: 100%;
    height: 1px;
    background: var(--rk-base-grey);
  }

  @include modifier("primary") {
    background-color: var(--rk-primary);
  }

  @include modifier("secondary") {
    background-color: var(--rk-secondary);
  }

  @include modifier("secondary-light") {
    background-color: var(--rk-secondary-light);
  }

  @include modifier("tertiary") {
    background-color: var(--rk-tertiary);
  }

  @include modifier("dark") {
    background-color: var(--rk-base-black);
  }

  @include modifier("light") {
    background-color: var(--rk-base-white);
  }

  @include modifier("centered") {
    margin: 30px auto;
  }

  @include modifier("no-margin") {
    margin: o auto;
  }
}
