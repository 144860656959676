/*==============================================================================
// File:        _icon.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared icon component - SASS
//============================================================================*/

.zen-icon {
  font-size: var(--rk-icon-size-df);
  padding: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("default") {
    color: var(--rk-text);
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);
  }

  @include modifier("white") {
    color: var(--rk-base-white);
  }

  @include modifier("helper") {
    margin: 0 0 0 -1px;
    position: relative;
    font-size: 10px;
    color: var(--rk-text);
    top: -3px;
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-icon-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-icon-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-icon-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-icon-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-icon-size-xs);
  }
}
