/*==============================================================================
// File:        _header.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main header styles for the website - SASS
//============================================================================*/

.zen-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 0;
  z-index: 50 !important;
}

.zen-menu-mobile {
  z-index: 40 !important;

  .offcanvas {
    max-width: 95vw;
  }

  .offcanvas-body {
    padding: 20px 0 150px;
    height: 100vh;
    overflow: scroll;
  }
}

.zen-menu .nav-link {
  font-weight: 600;
}

/*==============================================================================
// Elements
//============================================================================*/

.zen-header__main {
  justify-content: space-between;
  transition: all .2s ease-in-out;
  align-items: center;
  padding: 0 0 20px;
  display: flex;
  gap: 25px;
}

.zen-header__hamburger .zen-link {
  color: var(--rk-primary-light);
}

.zen-header__logo img {
  height: 120px;
  margin-top: 10px;
}

@media (max-width: var(--rk-viewport-md)) {
  .zen-header__logo img {
    height: 60px;
    margin: 10px 0;
  }
}

@media (max-width: var(--rk-viewport-sm)) {
  .zen-header__logo img {
    height: 50px;
    margin: 16px 0;
  }
}

.logo-default {
  display: flex;
}

.logo-scrolled {
  display: none;
}

.zen-menu-open .zen-header {
  z-index: 50 !important;
}

.blue .zen-header__main {
  background: var(--rk-primary);
}

.blue .zen-header__hamburger .zen-link {
  color: var(--rk-base-white);
}

.blue .scrolled .zen-header__hamburger .zen-link {
  color: var(--rk-primary);
}

.blue .scrolled .zen-menu .zen-link--search-mobile {
  color: var(--rk-primary) !important;
}

.blue .zen-menu .nav-link {
  color: var(--rk-base-white);
}

.blue .siteBody {
  padding-top: 113px;
}

.siteBody,
.scrolled.siteBody {
  margin-top: 0;
}

.white .zen-header__main {
  background: var(--rk-base-white);
}

.white .zen-link--search {
  color: var(--rk-primary);
}

.white .zen-header__hamburger .zen-link {
  color: var(--rk-primary-light);
}

.white .zen-menu .nav-link {
  color: var(--rk-primary-light);
}

.transparent .zen-header__hamburger .zen-link {
  color: var(--rk-base-white);
}

.transparent .zen-header__main {
  background: linear-gradient(180deg, rgba($base-black, .3), transparent 100%);
}

.transparent .zen-menu .nav-link {
  color: var(--rk-base-white);
}

.scrolled .zen-header__main {
  background: var(--rk-base-white);
  box-shadow: var(--rk-shadow-base);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
}

.scrolled .zen-header__logo img {
  height: 45px;
  margin: 16px 0;
}

.scrolled .logo-default {
  display: none;
}

.scrolled .logo-scrolled {
  display: flex;
}

.scrolled .zen-menu .nav-link {
  color: var(--rk-primary-light);
}

.scrolled .zen-menu .zen-btn--secondary {
  color: var(--rk-base-white);
}

.scrolled .zen-menu .zen-btn--enquire {
  border: 1px solid var(--rk-primary-light);
  background: var(--rk-primary-light);
  color: var(--rk-base-white);
}

@media (max-width: var(--rk-viewport-md)) {
  .scrolled .zen-menu .zen-btn--enquire {
    border: 1px solid var(--rk-base-grey-lighter);
    background: var(--rk-base-white);
    color: var(--rk-primary-light);
  }
}

.scrolled .zen-menu .zen-btn--enquire:hover {
  background: var(--rk-secondary-light);
  border: 1px solid var(--rk-secondary-light);
  color: var(--rk-primary-light);
}

.stickycontrols .zen-linkbar {
  position: fixed;
  margin: 0;
  top: 95px;
}

.siteBody.stickycontrols {
  margin-top: 0 !important;
}

.white .siteBody.scrolled,
.blue .siteBody.scrolled {
  margin-top: 120px;
}

.transparent .scrolled .zen-header__hamburger .zen-link {
  color: var(--rk-primary);
}
