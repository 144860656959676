/*==============================================================================
// File:        _slick-slider.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Slick Slider styles & overrides - SASS
//============================================================================*/

.slick-slider {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  display: block;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 50px;
  padding: 0;

  &:focus {
    outline: none;
  }

  @include adjoin("dragging") {
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  left: 0;
  top: 0;

  &:before,
  &:after {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  position: relative;
  margin: $m-md 0 0;
  justify-content: center;
  align-items: center;
  min-height: 1px;
  display: none;
  height: 100%;
  float: left;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  @include adjoin("slick-loading") {
    img {
      display: none;
    }
  }

  @include adjoin("dragging") {
    img {
      pointer-events: none;
    }
  }

  .slick-initialized & {
    display: flex;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    border: 1px solid transparent;
    display: block;
    height: auto;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.zen-horizontal-slider {
  @include media-breakpoint-down(md) {
    .slick-list {
      margin: 0;
    }
  }

  .slick-dots {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-left: 0;
  }

  .slick-dots li button {
    outline: none;
    border: none;
    border-radius: 50vw;
    content: "";
    color: var(--rk-base-grey-light);
    background: var(--rk-base-grey-light);
    font-size: 0;
    height: 10px;
    width: 10px;
    padding: 0;
  }

  .slick-dots .slick-active button {
    color: var(--rk-secondary-light);
    background: var(--rk-secondary-light);
  }
}

/*------------------------------------------------------------------------------
// Slick Slider Overrides
//----------------------------------------------------------------------------*/

.slick-arrow {
  position: absolute;
  line-height: 1em;
  background: none;
  font-size: 75px;
  color: var(--rk-primary);
  border: none;
  opacity: .6;
  padding: 0;
  z-index: 1;
  top: 45%;

  @include media-breakpoint-down(xs) {
    font-size: 55px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    outline: 0;
    opacity: 1;
  }

  @include adjoin("slick-prev") {
    left: 0;
  }

  @include adjoin("slick-next") {
    right: 0;
  }
}
