.zen-jumbo {
  @include modifier("primary") {
    background-color: var(--rk-primary);
  }

  @include modifier("secondary") {
    background-color: var(--rk-secondary);
  }

  @include modifier("tertiary") {
    background-color: var(--rk-tertiary);
  }

  @include modifier("light") {
    background-color: var(--rk-base-off-white);
  }

  @include modifier("dark") {
    background-color: var(--rk-base-black);
  }

  @include modifier("white") {
    background-color: var(--rk-base-white);
  }
}
