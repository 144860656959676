/*==============================================================================
// File:        _slider.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared slider component - SASS
//============================================================================*/

.zen-slider {
  @include modifier("edge") {
    .slick-list {
      margin: 0 !important;
      padding: 0 !important;
    }

    .slick-arrow {
      top: -50px;
      width: 50px;
      font-size: 20px;

      i {
        border-radius: 50vw;
        border: 1px solid var(--rk-base-black);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
      }
    }

    .slick-arrow.slick-prev {
      right: 80px;
      left: unset;
    }

    .slick-arrow.slick-next {
      right: 20px;
    }

    .slick-track {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
