/*==============================================================================
// File:        _user-agent.scss
// Package:     Joomla / Mr Zen
// Synopsis:    User agent targeting - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// IE
//----------------------------------------------------------------------------*/

/*==============================================================================
// ISSUE - https://github.com/twbs/bootstrap/issues/25242
//============================================================================*/

.ie {
  .img-fluid {
    width: 100%;
  }
}
