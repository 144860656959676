/*==============================================================================
// File:        _tag.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared tag component - SASS
//============================================================================*/

.zen-tag {
  padding: var(--rk-p-xs) var(--rk-p-df) var(--rk-p-xs) 25px;
  text-transform: uppercase;
  font-size: var(--rk-font-size-sm);
  color: var(--rk-base-white);
  font-weight: bold;
  line-height: 1em;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("default") {
    background: var(--rk-base-orange);
  }

  @include modifier("primary") {
    background: var(--rk-primary);
  }

  @include modifier("secondary") {
    background: var(--rk-secondary);
  }
}
