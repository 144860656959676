/*==============================================================================
// File:        _modal.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared modal component - SASS
//============================================================================*/

.zen-modal {
  margin: 0;
  border-radius: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("gallery") {
    a,
    a:hover {
      text-decoration: none;
      color: var(--rk-base-white);
    }

    .fontello {
      font-size: 2rem;
      transition: all .3s ease 0s;
      background: var(--rk-secondary);
      border: none;
      border-radius: 0;
      padding: 8px;
    }

    .carousel-control-prev {
      justify-content: start;
    }

    .carousel-control-next {
      justify-content: end;
    }
  }
}

/*------------------------------------------------------------------------------
// Extend modal element & modifiers
//----------------------------------------------------------------------------*/

%zen-modal {
  @extend .zen-modal;
}

%zen-modal--gallery {
  @extend .zen-modal--gallery;
}
