/*==============================================================================
// File:        _hotel.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared hotel component - SASS
//============================================================================*/

.zen-location {
  @include element("overview") {
    margin-top: -80px;
    position: relative;

    @media (max-width: $viewport-lg) {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    @include element("overview-image") {
      padding-left: 120px !important;
    }

    @include element("overview-content") {
      padding-right: 120px !important;
    }
  }

  @include element("island-image") {
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: -10;
    width: 400px;
    padding: 20px;
  }

  @include element("property-count") {
    width: 100%;
    text-align: center;
    padding: 20px;
    background: var(--rk-base-ivory);
  }

  @include element("weather-section") {
    background: var(--rk-base-ivory);

    @media (min-width: $viewport-lg) {
      &__inner {
        transform: translateX(70px);
      }
    }
  }

  @include element("weather-panel") {
    background: var(--rk-base-white);
    padding: 80px 50px;
    position: relative;

    @media (min-width: $viewport-lg) {
      margin-left: -160px;
      margin-top: 75px;
    }

    .col-inner {
      padding: 20px 0;
      border-top: 1px solid lightgrey;
    }

    .nav-link.active {
      background: var(--rk-secondary-light);
      color: var(--rk-base-white);
      border-radius: 0;
      border: none;
    }

    .nav-link {
      text-transform: uppercase;
      color: var(--rk-primary-light);
      border-radius: 0;
      border: none;
    }

    .nav-item {
      border-radius: none;
    }

    .nav-tabs {
      border: 0;
      margin: 30px 0;
    }
  }

  @include element("more-info") {
    background: var(--rk-base-ivory);

    .col-inner {
      background: var(--rk-base-white);
      padding: 30px;
    }

    .offset-top {
      @include media-breakpoint-down(sm) {
        position: relative;
        top: -20px;
      }
    }

    @media (min-width: $viewport-lg) {
      .right,
      .left {
        margin: 70px 0;
      }

      .right .col-inner {
        margin-left: -250px;
        z-index: 10;
      }

      .left .col-inner {
        margin-right: -250px;
        z-index: 10;
      }
    }
  }

  @include element("travel-options") {
    background: var(--rk-base-ivory);

    .col-inner {
      background: var(--rk-base-white);
      padding: 30px;
    }

    .offset-top {
      @include media-breakpoint-down(sm) {
        position: relative;
        top: -20px;
      }
    }

    @media (min-width: $viewport-lg) {
      .right,
      .left {
        margin: 70px 0;
      }

      .right .col-inner {
        margin-left: -250px;
        z-index: 10;
      }

      .left .col-inner {
        margin-right: -250px;
        z-index: 10;
      }
    }
  }

  .view-all-properties {
    width: 100%;
    margin: 48px auto 0;
    display: block;
    max-width: 400px;
    padding: 15px 20px 14px;
    text-align: center;
  }
}
