/*==============================================================================
// File:        _menu.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared menu component - SASS
//============================================================================*/
.top-item a {
  @extend %zen-link--menu-mobile;

  padding: 16px 20px;
}

/*------------------------------------------------------------------------------
// Offcanvas Styling Features
//----------------------------------------------------------------------------*/

.offcanvas {
  box-shadow: var(--rk-shadow-base);

  &.show {
    visibility: visible;
  }

  .menu-close {
    position: absolute;
    right: 15px;
    top: 33px;
    z-index: 200;
  }

  .moduletable {
    width: 100%;
  }

  .zen-list {
    li {
      a {
        text-decoration: none;
        width: 100%;
      }

      a:not(.accordion-link).active {
        color: var(--rk-secondary);
        font-weight: bold;
      }

      .deeper {
        a.sub-item.zen-link.zen-link--primary {
          padding: 5px 20px;
          display: block;

          &:hover {
            color: black;
          }
        }

        .zen-btn--mobile-nav {
          padding: 20px !important;

          &:hover {
            color: white !important;
          }
        }
      }
    }

    .child-sub-item {
      text-transform: capitalize;

      a {
        color: var(--rk-primary);
        font-weight: 400;
      }
    }
  }

  .zen-flyout-back {
    &:hover,
    &:focus {
      cursor: pointer;
    }

    span {
      font-weight: 700;
    }

    i {
      margin-right: 10px;
    }
  }
}

/*------------------------------------------------------------------------------
// Zen Menu Styling
//----------------------------------------------------------------------------*/

.zen-menu {
  .dropdown-toggle {
    color: #000;
    padding: 5px 10px 5px 5px;
    background: transparent;
    border: none;

    &:hover {
      background: transparent;
      border: none;
      color: var(--rk-primary);
    }
  }

  .zen-link--title > a {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--rk-secondary);
  }

  .dropdown-menu {
    top: 100%;
    border: 1px solid var(--rk-base-grey);
    border-radius: 0;

    &:before {
      content: "";
      background: var(--rk-secondary-light);
      width: 60%;
      height: 5px;
      position: absolute;
      top: -5px;
      left: 0;
    }
  }

  .two-column-heading:hover > .dropdown-menu {
    display: flex !important;
    padding: 20px;
  }

  .nav-link {
    @extend %zen-link--menu-main;

    font-family: var(--rk-secondary-font);
    text-transform: unset;
    color: var(--rk-base-white);
  }

  .sub-item {
    display: inline-block;
    padding: var(--rk-p-xs) var(--rk-p-sm);
    width: 100%;
  }

  /*------------------------------------------------------------------------------
  // Elements
  //----------------------------------------------------------------------------*/

  @include element("main") {
    .mobile {
      box-shadow: var(--rk-shadow-base);
    }

    .nav-header,
    .nav-link {
      @extend %zen-link--menu-main;

      text-transform: uppercase;
    }

    .nav-header {
      font-family: var(--rk-secondary-font);
      text-transform: uppercase;
      font-weight: 700;
      color: var(--rk-secondary-light);
    }

    .sub-item {
      display: inline-block;
      padding: var(--rk-p-sm) var(--rk-p-sm);
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      @include insert-transition(all, .3s, ease-in-out, 0s);

      background: var(--rk-base-white);
      z-index: var(--rk-z-index-top);
      font-weight: normal;
      min-width: 300px;
      position: fixed;
      overflow: auto;
      max-width: 65%;
      right: -480px;
      height: 100%;
      opacity: 0;
      margin: 0;
      top: 0;
    }
  }

  @include element("footer") {
    .nav-header,
    .nav-link {
      background: inherit;
    }
  }

  .mod-menu__heading.nav-header.zen-link--menu-sub.dropdown-item {
    padding: 15px;
  }
}

/*------------------------------------------------------------------------------
// Mobile menu
//----------------------------------------------------------------------------*/

.zen-menu-open {
  @include media-breakpoint-down(lg) {
    max-height: 100vh;
    overflow: hidden;

    .zen-overlay {
      display: block;
      opacity: 1;
    }

    .sppb-section,
    .zen-header,
    .zen-body {
      z-index: auto !important;
    }

    .sp-page-builder {
      z-index: -1;
    }

    .zen-menu__main {
      transform: translateX(-480px);
      opacity: 1;
    }
  }
}

.sticky-marker {
  position: absolute;
  right: 10px;
  height: 1px;
  width: 1px;
  bottom: 0;
}

.scroll-transition {
  margin-top: 60px;
}

.fixed-menu {
  @include insert-transition(all, .3s, ease, 0s);

  z-index: var(--rk-z-index-middle);
  background: var(--rk-default);
  position: fixed;
  width: 100%;
  top: 0;
}

/*------------------------------------------------------------------------------
// Image Menu Styling
//----------------------------------------------------------------------------*/

.zen-image-menu {
  .zen-image-menu--dropdown {
    position: absolute;
    top: 70px;
    width: 100vw;
    left: -15px;
    right: 0;
    margin: 0 auto;
    background: var(--rk-base-white);
    transition: all .1s ease-in-out;
    box-shadow: 0 0 20px rgba(var(--rk-base-black), .3);
    padding: 0 15px;

    .dropdown-item {
      display: flex;
      flex-direction: column;
    }

    .nav-item a {
      border-radius: 8px;

      span {
        font-size: 18px;
        text-align: left;
        padding-top: 5px;
        color: var(--rk-base-black);
      }
    }
  }

  .dropdown {
    position: inherit;
  }
}

/*------------------------------------------------------------------------------
// Flyout Menu Styling
//----------------------------------------------------------------------------*/

.flyout-menu-offcanvas,
.flyout-submenu-offcanvas {
  position: fixed;
  top: 0;
  background: var(--rk-base-white);
  z-index: 100;
  max-width: 400px;
  width: 95vw;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  box-shadow: var(--rk-shadow-base);
}

.offcanvas-backdrop {
  background: rgba(0, 0, 0, 60%) !important;
  backdrop-filter: blur(10px) !important;
}

.offcanvas-backdrop.show {
  opacity: 1;
}

.two-column .zen-list--menu-dropdown {
  column-count: 2;
  padding: 20px;
}

.three-column .zen-list--menu-dropdown {
  column-count: 3;
  padding: 20px;
}

.four-column .zen-list--menu-dropdown {
  column-count: 4;
  padding: 20px;
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-menu {
  @extend .zen-menu;
}

%zen-menu__footer {
  @extend .zen-menu__footer;
}
