/*==============================================================================
// File:        _blog.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared blog component - SASS
//============================================================================*/

.zen-blog {
  overflow: hidden;
  padding: 0;

  @include element("article-image") {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 700px;
  }

  @include element("header") {
    margin-top: 210px;
    padding-bottom: 100px;
  }

  /*==============================================================================
  // BLOG SINGLE ARTICLE STYLES
  //============================================================================*/

  @include element("article-pagination") {
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin: 10px;
    }

    .icon-chevron-left,
    .icon-chevron-right {
      @extend %fontello;

      font-family: fontello;
      vertical-align: middle;
      padding: 0;
    }
  }

  .category-list-item .category-list-item {
    position: relative;
  }

  @include element("article-heading") {
    max-width: 980px;
    margin: 50px auto;
  }

  @include element("main-body") {
    max-width: 960px;
    margin: 50px auto;
    padding: 20px 0;
  }

  @include element("article-infobar") {
    border-top: 1px solid var(--rk-gray);
  }

  @include element("category-list") {
    border-top: 1px solid var(--rk-base-grey-lighter);

    .category-list-item {
      text-transform: uppercase;
      font-size: var(--rk-font-size-df);
      color: var(--rk-primary-light);
      text-decoration: none;
      transition: all .2s ease-in-out;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        width: 0;
        height: 5px;
        bottom: -10px;
        background: var(--rk-secondary-light);
        transition: .3s ease-in-out;
      }

      &:hover {
        color: var(--rk-secondary-light);

        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  @include element("article-hero") {
    background: var(--rk-primary);
  }

  @include element("items-leading") {
    margin-top: -100px;
    margin-bottom: 60px;
    position: relative;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }
  }

  @include element("items-more") {
    max-width: 960px;
  }

  @include element("featured-card") {
    position: relative;

    .card-image {
      width: 100%;
    }

    .card-overlay {
      background: linear-gradient(25deg, rgba($base-black, .5) 0%, rgba($base-black, 0) 100%);
      position: absolute;
      inset: 0;
    }

    .card-inner {
      position: absolute;
      inset: 0;
      top: 25%;

      a {
        text-decoration: none;
      }

      h3 {
        color: var(--rk-base-white);
        text-transform: uppercase;
        text-shadow: 0 0 10px rgba(0, 0, 0, 50%);
      }

      p {
        text-shadow: 0 0 10px rgba(0, 0, 0, 50%);
      }

      .item-content {
        color: var(--rk-base-white);
      }
    }
  }

  @include element("leading-card") {
    text-decoration: none;
    position: relative;

    .card-overlay {
      background: linear-gradient(25deg, rgba(var(--rk-base-black), .7) 0%, rgba(var(--rk-base-black), 0) 100%);
      position: absolute;
      left: 15px;
      right: 15px;
      top: 0;
      bottom: 0;
    }

    .item-content {
      position: absolute;
      bottom: 30px;
      left: 30px;
      right: 30px;

      h4 {
        color: var(--rk-base-white);
        text-shadow: 0 0 10px rgba(0, 0, 0, 50%);
      }

      .date {
        width: 100px;
        color: var(--rk-base-white);
      }

      hr {
        color: var(--rk-base-white);
      }
    }
  }

  @include element("card-more") {
    border-top: 1px solid var(--rk-base-grey-light);
    padding: 20px 0;

    a {
      text-decoration: none;
    }

    .date {
      color: var(--rk-base-grey-darker);
      font-style: italic;
    }

    .blog-image {
      height: 250px;
      background-color: #F4F4F4;
      background-size: 120%;
      background-position: center;
      background-repeat: no-repeat;
      transition: all .3s ease-in-out;
    }

    &:hover {
      .blog-image {
        background-size: 130%;
      }
    }
  }
}

.pagination__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-angle-right:after {
    @extend %fontello;

    content: "\e81b";
    font-family: fontello;
  }

  .icon-angle-left:after {
    @extend %fontello;

    content: "\e819";
    font-family: fontello;
  }

  .icon-angle-double-right:after {
    @extend %fontello;

    content: "\e81b\e81b";
    letter-spacing: -6px;
    font-family: fontello;
  }

  .icon-angle-double-left:after {
    @extend %fontello;

    content: "\e819\e819";
    letter-spacing: -6px;
    font-family: fontello;
  }

  .page-item:nth-last-child(3) {
    display: none;
  }
}
