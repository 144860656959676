/*==============================================================================
// File:        _rsforms.scss
// Package:     Joomla / Mr Zen
// Synopsis:    RSForms styling & overrides - SASS
//============================================================================*/

.rsform {
  .rsform-select-box,
  .rsform-input-box {
    @extend %zen-input;

    margin: 0 $m-md 0 0;
  }

  .form-label {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--rk-text);
  }

  .rsform-text-box,
  .form-control {
    border-color: $base-grey;
    color: var(--rk-primary);

    &::-webkit-input-placeholder {
      color: var(--rk-primary-light);
    }

    &:-ms-input-placeholder {
      color: var(--rk-primary-light);
    }

    &::placeholder {
      color: var(--rk-primary-light);
    }
  }
}

.rsform-block-inline {
  display: inline-block;
  vertical-align: top;
}

.rsform-block-title h3 {
  color: var(--rk-text);
  margin: 0;
}

.rsform-submit-button {
  @extend %zen-btn;
}

.rsform-calendar-button {
  @extend %zen-btn;

  border-bottom-left-radius: 0;
  border: 1px solid var(--rk-primary);
  border-top-left-radius: 0;

  @include adjoin("btn.btn-secondary") {
    @extend %zen-btn;

    border-bottom-left-radius: 0;
    border: 1px solid var(--rk-primary);
    border-top-left-radius: 0;
  }
}

.rsfpStripeContainer {
  text-align: center;
  background: var(--rk-alternate-dark);
  padding: 50px;
  font-size: 22px;
  margin: 50px auto;
  max-width: 960px;
}
