.zen-mega {
  max-width: 1600px;
  background: white;
  margin: 0 auto;
  user-select: none;
  border-bottom: 3px solid var(--rk-primary);
  border-top: 1px solid  var(--rk-base-grey-lighter);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 10%);

  @include element("wrapper") {
    position: absolute;
    left: 0;
    right: 0;
    top: 130px;
  }

  @include element("level-1-wrapper") {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @include element("heading") {
    color: var(--rk-primary);
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      width: 80px;
      left: 0;
      height: 4px;
      background: var(--rk-base-grey-lighter);
    }
  }

  @include element("level-1") {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    background: var(--rk-base-white);
    z-index: 100;
    border-top: 1px solid var(--rk-base-grey-lighter);
    border-bottom: 1px solid var(--rk-base-grey-lighter);
  }

  @include element("heading") {
    a {
      color: var(--rk-primary);
      text-decoration: none;
      font-size: 22px;
    }
  }

  @include element("level-1-link") {
    background: var(--rk-base-white);
    color: var(--rk-primary);
    border: 1px solid var(--rk-base-grey-light);

    &:hover {
      color: var(--rk-primary);
      background: var(--rk-base-grey-lighter);
      border: 1px solid var(--rk-primary);
    }

    &.active {
      color: var(--rk-base-white);
      background: var(--rk-secondary);
      border: 1px solid var(--rk-secondary);
    }
  }

  @include element("level-1-inner") {
    padding: 50;
  }

  @include element("level-1-tab") {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--rk-base-grey-light);
  }

  @include element("level-1-inner") {
    padding: 40px 0;
  }

  @include element("level-2-wrapper") {
    margin: 0;
    list-style: none;
    padding: 20px 0;
    border-bottom: 2px solid var(--rk-base-grey-lighter);

    @media (max-width: 1400px) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  @include element("level-2") {
    border: none;
    padding: var(--rk-p-xs);

    .zen-mega__link {
      color: var(--rk-base-black);
      text-decoration: none;
      text-transform: none;
      font-size: 16px;
      font-weight: 400;
    }

    .zen-mega__heading {
      color: var(--rk-primary);
      text-decoration: none;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  @include element("view-all") {
    .zen-btn--link {
      text-decoration: underline;
      border: none;
    }
  }

  @include element("level-2-image-card") {
    padding: 80px 0;
    border-radius: 4px;
    background-position: center;
    transition: all .4s ease-in-out;

    &:hover {
      background-position: top;
      transform: scale(1.02);
    }

    h3 {
      text-shadow: 0 0 8px rgba(0, 0, 0, 50%);
    }

    @media (max-width: 1400px) {
      padding: 40px 0;

      h3 {
        font-size: 18px;
      }
    }
  }

  @include element("level-3-image-card") {
    padding: 80px 0;
    border-radius: 4px;

    h3 {
      text-shadow: 0 0 8px rgba(0, 0, 0, 50%);
    }
  }

  @include element("level-3-wrapper") {
    column-count: 2;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
  }

  @include element("level-3") {
    border: none;
    background: rgba(0, 0, 0, 0%);
    transition: all .2s ease-in-out;
    padding: var(--rk-p-xs);
    width: 100%;
    list-style: none;

    &:hover {
      background: var(--rk-base-grey-lighter);
    }

    .zen-mega__link {
      color: var(--rk-base-black);
      text-decoration: none;
      text-transform: none;
      font-size: 16px;
      width: 100%;
      display: block;
      transition: all .2s ease-in-out;
      font-weight: 400;

      &:hover {
        background-color: var(--rk-base-grey-lighter);
      }
    }
  }
}

.scrolled .zen-mega__wrapper {
  top: 97px;
}
