.zen-properties-search {
  .destinationsList .ais-RefinementList-list {
    column-count: 2;
  }

  .facilitiesList .ais-RefinementList-list {
    column-count: 2;
  }

  @include element("refinements") {
    .ais-ClearRefinements-button--disabled {
      display: none;
    }

    .ais-CurrentRefinements-category {
      padding: 10px;
      background: var(--rk-base-ivory);
      border: 1px solid var(--rk-base-ivory);
      margin: 0 5px;
      color: var(--rk-primary-light);
    }

    .ais-CurrentRefinements-label {
      display: none;
    }

    .ais-CurrentRefinements-list {
      list-style: none;
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      margin-bottom: 0;
      gap: 20px 0;
    }

    .ais-CurrentRefinements {
      margin-top: 10px;
    }

    .ais-CurrentRefinements-delete {
      margin-left: 10px;
      background: none;
      border: none;
      color: var(--rk-primary-light);
    }

    .ais-ClearRefinements-button {
      border-radius: 4px;
      background: var(--rk-base-white);
      border: 1px solid rgba(3, 40, 80, 20%);
      padding: 10px 20px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .ais-ClearRefinements-button a {
      color: var(--rk-primary-light);
      text-decoration: none;
    }
  }

  @include element("mobile-filters") {
    .accordion-item {
      border: none !important;
      border-bottom: 1px solid var(--rk-base-grey-dark);
    }

    .offcanvas-body {
      margin-bottom: 60px;
    }

    .offcanvas-buttons {
      position: absolute;
      bottom: 0;
      background: var(--rk-base-white);
      left: 0;
      right: 0;
      box-shadow: 0 0 10px rgba($base-black, .3);
      padding: 10px;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }

    .ais-ClearRefinements-button--disabled {
      display: none;
    }

    .offcanvas-header {
      border-bottom: 1px solid var(--rk-base-grey-dark);
    }

    .ais-ClearRefinements-button {
      padding: 10px 30px;
      border: 1px solid var(--rk-base-grey-dark);
      background: var(--rk-base-white);

      a {
        font-weight: 700;
        color: var(--rk-primary);
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    .ais-RefinementList-labelText {
      font-size: 14px;
    }

    .accordion-button {
      font-weight: 700 !important;
      color: var(--rk-primary);
      border-bottom: 1px solid var(--rk-base-grey-dark);
    }

    .accordion-button:not(.collapsed) {
      background: var(--rk-base-white);
    }

    .offcanvas {
      height: 80vh;
    }

    .facet {
      position: relative;
    }

    .ais-RefinementList-list {
      list-style: none;
      padding: 0;
    }

    .ais-RefinementList-item {
      padding: 10px 0;
    }

    .ais-RefinementList-checkbox {
      margin-right: 10px;
    }

    .ais-RefinementList-count {
      margin-left: 5px;
      background: var(--rk-base-white);
      padding: 5px;
      border-radius: 5px;
      border: 1px solid var(--rk-base-grey-dark);
    }
  }

  .keyword-search-mobile {
    width: 100%;

    .ais-SearchBox-form {
      display: flex;
    }

    .ais-SearchBox-input {
      width: 100%;
      background: var(--rk-primary-light);
      border: none;
      padding: 15px;
      color: white;
      font-size: 14px;
    }

    .ais-SearchBox-submit {
      border: none;
      background: var(--rk-secondary);
      width: 80px;
    }

    .ais-SearchBox-reset {
      background: var(--rk-base-grey);
      border: none;
      width: 80px;
    }
  }

  // Filters Bar
  @include element("filters") {
    border-bottom: 1px solid var(--rk-base-grey-dark);

    .ais-SearchBox-form {
      display: flex;
      gap: 20px;
    }

    .ais-SearchBox-input {
      width: 100%;
      font-size: 18px;
      padding: 30px;
      height: 40px;
      border-radius: 10px;
      border: 1px solid var(--rk-base-grey);
    }

    .ais-SearchBox-submitIcon {
      width: 20px;
      height: 16px;
    }

    .ais-SearchBox-reset {
      width: 60px;
      border: none;
      border-radius: 10px;
    }

    .ais-SearchBox-submit {
      border: none;
      background: var(--rk-primary);
      padding: 0 20px;
      border-radius: 10px;

      svg {
        fill: var(--rk-base-white);
      }
    }

    .facet-dropdown {
      position: absolute;
      top: 40px;
      left: 0;
      width: 200px;
      z-index: 100;
    }

    .facet {
      position: relative;
    }

    .ais-RefinementList-list {
      list-style: none;
      padding: 15px;
    }

    .ais-RefinementList-item {
      padding: 10px 0;
    }

    .ais-RefinementList-checkbox {
      margin-right: 10px;
    }

    .ais-RefinementList-label {
      color: var(--rk-primary-light);
    }

    .ais-RefinementList-count {
      margin-left: 5px;
      background: none;
      padding: 5px;
      border-radius: 5px;
    }

    .ais-RefinementList-count:before {
      content: "(";
    }

    .ais-RefinementList-count:after {
      content: ")";
    }

    .nav-link.dropdown-toggle {
      border-radius: 10px;
      background: none;
      font-weight: 700;
      color: var(--rk-primary);
      text-transform: none;
      padding: 15px;
      height: 100%;
      border: 4px solid var(--rk-base-white);
      font-size: 15px;
    }

    .nav-link.dropdown-toggle:hover {
      background: var(--rk-base-off-white);
      border-radius: 10px;
    }

    .dropdown-toggle:after {
      font-size: 10px;
      color: var(--rk-primary);
      margin-left: 5px;
    }

    .nav-link.dropdown-toggle.show {
      border-bottom: 4px solid var(--rk-secondary);
    }
  }

  // Results Section
  @include element("results") {
    .hits ol {
      display: grid;
      padding: 0;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      list-style: none;

      @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .zen-card {
      border: none;
      border: 1px solid var(--rk-base-off-white);

      .ais-Highlight {
        color: var(--rk-base-black);
        font-weight: 500;
        font-style: italic;
      }

      @include element("property-location-name") {
        color: var(--rk-base-black);
        margin: 5px 0;
        font-weight: bold;
      }

      @include element("property-location-wrapper") {
        .icon-location-pin {
          font-size: 24px;
          margin-left: -4px;
          color: #000;
        }
      }

      @include element("property-content") {
        padding: 15px;
        background: var(--rk-base-off-white);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @include element("property-rating") {
        color: var(--rk-gold);
        margin: 0;
        padding: 0;
        font-weight: bold;
        position: relative;
        top: 2px;
      }

      @include element("humclub-logo") {
        max-width: 80px;
      }
    }

    .card-image {
      height: 300px;
      background-color: var(--rk-base-grey-dark);
    }
  }

  // Pagination
  @include element("pagination") {
    .ais-Pagination {
      margin: 50px auto;
    }

    ul {
      list-style: none;
      display: flex;
      gap: 10px;
    }

    .ais-Pagination-item--selected {
      background: var(--rk-base-grey);
    }

    .ais-Pagination-item {
      padding: 5px;
      border-radius: 50vw;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ais-Pagination-link {
        text-decoration: none;
        color: var(--rk-primary);
        font-weight: 700;
      }
    }

    .ais-Pagination-item--firstPage,
    .ais-Pagination-item--lastPage {
      background: var(--rk-base-white);
      border: 1px solid var(--rk-base-grey-darker);

      .ais-Pagination-link {
        color: var(--rk-primary);
        text-decoration: none;
        font-weight: 700;
      }
    }

    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--nextPage {
      background: var(--rk-secondary);

      .ais-Pagination-link {
        color: var(--rk-base-white);
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}
