/*==============================================================================
// File:        _article.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared article component - SASS
//============================================================================*/

.zen-article {
  margin: 0 $m-df $m-df;

  /*==============================================================================
    // Elements
    //============================================================================*/

  @include element("image") {
    position: relative;
    margin: 0 0 $m-xs;
    overflow: hidden;
    display: flex;
  }

  @include element("title") {
    margin: 0 0 $m-df;
  }

  @include element("content") {
    flex-direction: column;
    position: relative;
    padding: $p-xs;
    display: flex;
  }
}

.sppb-addon-articles {
  position: relative;

  .slick-list {
    margin: 0;
  }

  .slick-arrow.slick-prev {
    right: 60px;
    left: unset;
    top: -50px;
    border-radius: 50vw;
    border: 1px solid var(--rk-base-black);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-arrow.slick-next {
    right: 0;
    top: -50px;
    border-radius: 50vw;
    border: 1px solid var(--rk-base-black);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sppb-article-info-wrap {
    justify-content: space-between;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-bottom: 1px solid rgba(var(--rk-base-white), .3);
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    h3 {
      color: var(--rk-base-white);
      font-size: 22px;
      text-shadow: 0 0 5px rgba(var(--rk-base-black), .7);
    }

    .sppb-meta-date {
      color: var(--rk-base-white);
    }
  }
}

.zen-article-slider {
  background-size: 270%;
  transition: all .25s ease-in-out;

  &:hover {
    background-size: 280%;
  }
}

/*------------------------------------------------------------------------------
    // Extend article element & modifiers
    //----------------------------------------------------------------------------*/
%zen-article {
  @extend .zen-article;
}

%zen-article__image {
  @extend .zen-article__image;
}

%zen-article__title {
  @extend .zen-article__title;
}

%zen-article__content {
  @extend .zen-article__content;
}
