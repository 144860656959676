/*==============================================================================
// File:        _pagination.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared pagination component - SASS
//============================================================================*/

.zen-pagination {
  padding: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("item") {
    color: var(--rk-text);
  }
}

/*------------------------------------------------------------------------------
// Extend pagination element & modifiers
//----------------------------------------------------------------------------*/

%zen-pagination {
  @extend .zen-pagination;
}

%zen-pagination__item {
  @extend .zen-pagination__item;
}
