.zen-tour {
  @include element("itinerary") {
    max-width: 1200px;
    margin: 0 auto;

    h3 {
      font-size: 36px;
    }

    .day-section {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: start;
    }

    .day-tag {
      text-align: center;
      background: var(--rk-primary-light);
      color: var(--rk-base-white);
      padding: 5px 10px;
      z-index: 20;
      white-space: nowrap;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 16px;
    }

    .day-section:after {
      content: "";
      width: 3px;
      height: 100%;
      left: 50%;
      right: 50%;
      border-right: 1px dashed var(--rk-primary-light);
      position: absolute;
      top: 0;
      bottom: 0;
    }

    .day-content {
      h4 {
        font-size: 30px;
      }
    }
  }

  @include element("intro") {
    p,
    span {
      font-size: 19px;
    }
  }
}
