/*==============================================================================
// File:        _styles.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main styles for the website - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Layout Styles
//----------------------------------------------------------------------------*/

html {
  overflow-x: hidden;
}

main {
  padding: 0;
}

body {
  @include fluid-prop(
    font-size,
    var(--rk-viewport-full-min),
    var(--rk-viewport-xl),
    var(--rk-font-size-sm),
    var(--rk-font-size-df)
  );

  font-family: var(--rk-primary-font);
  font-weight: normal;
  position: relative;
  color: var(--rk-text);
  height: 100%;
  z-index: 0;

  @include media-breakpoint-down(lg) {
    overflow-x: hidden;
    display: inherit;
  }
}

body.modal-open {
  padding-right: 0 !important;
}

a {
  color: var(--rk-secondary);

  &:hover,
  &:focus {
    color: var(--rk-secondary);
  }
}

p {
  margin-bottom: var(--rk-m-df);
}

hr {
  border-color: var(--rk-base-grey-darker);
}

iframe {
  border: none;
}

.zen-back-to-top {
  background: #444;
  z-index: var(--rk-z-index-middle);
  position: fixed;
  display: none;
  bottom: 10px;
  left: 10px;

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.zen-overlay {
  background: transparent;
  background: rgba(var(--rk-base-black), .4);
  position: fixed;
  display: none;
  height: 100%;
  z-index: var(--rk-z-index-25);
  width: 100%;
  opacity: 0;
  top: 0;

  &:hover {
    cursor: pointer;
  }
}

.global-search-offcanvas {
  max-height: 200px !important;
  overflow: hidden;

  .offcanvas-body {
    width: 100%;
    overflow: hidden;
  }

  @media (min-width: 991px) {
    .offcanvas-body {
      width: 50%;
      overflow: hidden;
      margin: 0 auto;
    }
  }
}

/*------------------------------------------------------------------------------
// Example Class Animation for Lazyload
//----------------------------------------------------------------------------*/

.zen-lazy-load {
  display: block;
  height: 1px;
  width: 1px;
}

.in-viewport {
  @include insert-transition(all, .3s, ease, 0s);

  visibility: hidden;
  display: none;
  opacity: 0;
  height: 0;

  @include adjoin("active-viewport") {
    @include insert-transition(all, .3s, ease, 0s);

    visibility: visible;
    display: block;
    height: auto;
    opacity: 1;
  }
}

/*------------------------------------------------------------------------------
// Zen Main Styles
//----------------------------------------------------------------------------*/

.zen-image-shadow img {
  filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 70%));
}

.zen-flex-center {
  @include flex--center;
}

.zen-flex-end {
  @include flex--end;
}

.zen-heading-light {
  @extend %zen-title--light;
}

.zen-heading-dark {
  @extend %zen-title--dark;
}

.zen-heading-primary {
  @extend %zen-title--primary;
}

.zen-heading-secondary {
  @extend %zen-title--secondary;
}

.zen-text-lead {
  @extend %zen-text--lead;
}

.zen-font-secondary {
  @extend %zen-text--font-secondary;
}

.zen-footer-menu {
  @extend %zen-menu__footer;
}

.zen-text-default {
  color: var(--rk-default-dark);
}

.zen-bg-default {
  background: var(--rk-base-grey);
}

.zen-bg-default-light {
  background: var(--rk-light);
}

.zen-bg-default-dark {
  background: var(--rk-dark);
}

.zen-valid-error {
  border-color: var(--rk-pink);
  background-color: var(--rk-light);
  color: var(--rk-red);
}

.zen-error-text {
  font-size: var(--rk-font-size-df);
  color: var(--rk-error);
}

.zen-white-bg {
  background: var(--rk-base-white);
}

.zen-emphasized {
  font-style: normal;
}

.zen-no-shadow {
  box-shadow: none;
}

.zen-bold {
  font-weight: bold;
}

.zen-capitalize {
  text-transform: capitalize;
}

.zen-line-through {
  text-decoration: line-through;
}

.zen-hide {
  display: none;
}

.zen-inline-block {
  display: inline-block;
}

.zen-overflow-hidden {
  overflow: hidden;
}

.zen-no-transform {
  text-transform: none;
}

.zen-uppercase {
  text-transform: uppercase;
}

.zen-underline {
  text-decoration: underline;
}

.w-960 {
  max-width: 960px;
}

.zen-nomargin {
  margin: 0;
}

.zen-margin-auto {
  margin: 0 auto;
}

.zen-row {
  margin: 0 -15px;
}

.zen-col {
  float: left;
}

.zen-width-full {
  width: 100%;
}

.zen-width-half {
  width: 50%;
}

.zen-width-content {
  width: fit-content;
}

.zen-nopad {
  padding: 0;
}

.zen-nopad-top {
  padding-top: 0;
}

.zen-nopad-right {
  padding-right: 0;
}

.zen-nopad-bottom {
  padding-bottom: 0;
}

.zen-nopad-left {
  padding-left: 0;
}

.zen-col-border-left {
  border-left: 1px solid var(--rk-primary);
}

.zen-col-border-right {
  border-right: 1px solid var(--rk-primary);
}

/*------------------------------------------------------------------------------
// Login Page Styling
//----------------------------------------------------------------------------*/

.profile {
  padding: var(--rk-p-df) 0;

  .profile-core {
    margin: var(--rk-m-full-df);
  }
}

.profile-edit {
  padding: var(--rk-p-df) 0;
}

/*------------------------------------------------------------------------------
// Error Page Styling
//----------------------------------------------------------------------------*/

.error-page {
  .jumbotron {
    border-top: 1px solid var(--rk-base-white);
    background: var(--rk-secondary);
  }
}

/*------------------------------------------------------------------------------
// Recapcha Badge Hide
//----------------------------------------------------------------------------*/

.grecaptcha-badge {
  opacity: 0;
}
