.zen-infobar {
  position: relative;
  background: var(--rk-primary);
  margin: -30px;
  z-index: 20;
  color: var(--rk-base-white);

  .col {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-right: 1px solid var(--rk-alternate);
    margin: 8px 0;

    &:first-of-type {
      border-left: 1px solid var(--rk-alternate);
    }

    p {
      margin-bottom: 0;
      padding: 20px;
    }
  }
}

.infobar-wrapper {
  max-width: 1600px;
  margin: 0 auto;
}
