/*==============================================================================
// File:        _panel.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared panel component - SASS
//============================================================================*/

.zen-panel {
  @include insert-transition(all, 1s, ease, .5s);

  z-index: var(--rk-z-index-middle);
  background: var(--rk-light);
  visibility: hidden;
  position: fixed;
  padding: var(--rk-p-df);
  opacity: 0;

  @include adjoin("show-panel") {
    visibility: visible;
    opacity: 1;
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("content") {
    @include media-breakpoint-down(lg) {
      font-size: var(--rk-font-size-df);
    }

    @include media-breakpoint-down(sm) {
      font-size: var(--rk-font-size-xs);
    }
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("policy") {
    border-top: 1px solid var(--rk-base-grey);
    bottom: -200px;
    width: 100%;

    @include adjoin("show-panel") {
      bottom: 0;
    }
  }
}

/*------------------------------------------------------------------------------
// Extend panel element & modifiers
//----------------------------------------------------------------------------*/

%zen-panel {
  @extend .zen-panel;
}
