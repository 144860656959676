/*==============================================================================
// File:        _button.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared button component - SASS
//============================================================================*/

.zen-btn {
  @include insert-transition(all, .5s, ease, .1s);

  font-family: var(--rk-secondary-font);
  letter-spacing: var(--letter-space-df);
  padding: var(--rk-btn-p-sm) var(--rk-btn-p-xl);
  text-transform: uppercase;
  z-index: var(--rk-z-index-bottom);
  font-size: var(--rk-font-size-df);
  font-weight: var(--rk-semi-bold);
  color: var(--rk-base-off-white);
  text-decoration: none;
  display: inline-block;
  background: var(--rk-primary);
  position: relative;
  line-height: 1em;
  border-radius: 4px;
  border: 1px solid transparent;

  &:active,
  &:hover,
  &:focus {
    background: var(--rk-primary-light);
    color: var(--rk-base-off-white);
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--rk-alternate-mix-light);
    border-color: var(--rk-alternate-mix-light);
    color: var(--text);
    opacity: .6;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("primary") {
    color: var(--rk-base-white) !important;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-primary) !important;
      border-color: var(--rk-primary);
    }
  }

  @include modifier("invert") {
    background: var(--rk-primary-light);

    &:hover,
    &:focus {
      background: var(--rk-primary);
    }
  }

  @include modifier("link") {
    background: none;
    color: var(--rk-primary);
    border: 1px solid var(--rk-base-grey-lighter);
    padding: 20px;
    font-weight: 400;

    &:hover,
    &:focus {
      background: var(--rk-base-grey-lighter);
      color: var(--rk-primary-light);
    }
  }

  @include modifier("secondary") {
    background: var(--rk-secondary);
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      background: var(--rk-secondary-light);
      color: var(--rk-base-white);
    }
  }

  @include modifier("alternate") {
    background: var(--rk-alternate);
    color: var(--rk-text);

    &:hover,
    &:focus {
      background: var(--rk-alternate-light);
      color: var(--rk-text);
    }
  }

  @include modifier("opaque") {
    background: transparent;
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      background: transparent;
      color: var(--rk-base-white);
    }
  }

  @include modifier("default") {
    background: var(--rk-default);

    &:hover,
    &:focus {
      background: var(--rk-default-light);
    }
  }

  @include modifier("dark") {
    background: var(--rk-dark);

    &:hover,
    &:focus {
      background: var(--rk-dark);
      color: var(--rk-secondary);
    }
  }

  @include modifier("warning") {
    background: var(--rk-secondary-light);

    &:hover,
    &:focus {
      background: var(--rk-secondary-light);
    }
  }

  @include modifier("danger") {
    background: var(--rk-primary-light);

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
    }
  }

  @include modifier("icon-submit") {
    font-size: var(--rk-font-size-lg);
    padding: var(--rk-p-sm) var(--rk-p-md);
    background: var(--rk-primary);
    position: absolute;
    color: var(--rk-base-white);
    right: 1px;
    top: 1px;

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
      color: var(--rk-base-off-white);
      outline: none;
    }
  }

  @include modifier("full-size") {
    width: 100%;
  }

  @include modifier("extra-pad-lg") {
    padding: var(--rk-p-md) var(--rk-p-lg);
  }

  @include modifier("extra-pad-md") {
    padding: var(--rk-p-sm) var(--rk-p-md);
  }

  @include modifier("extra-pad-sm") {
    padding: var(--rk-p-sm) var(--rk-p-sm);
  }

  @include modifier("scroll") {
    border: 1px solid var(--rk-primary-mix-dark);
    border-radius: var(--rk-border-radius-df);
    background: var(--rk-primary);
    color: var(--rk-base-white);
    font-size: 30px;
    display: none;
    padding: 8px;

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
      color: var(--rk-base-white);
    }

    @include adjoin("show-btn") {
      display: flex;
    }

    :before {
      vertical-align: top;
    }
  }

  /*------------------------------------------------------------------------------
	// Header Enquire Buttons
	//----------------------------------------------------------------------------*/
  @include modifier("header-enquire-blue") {
    border: 1px solid rgba(var(--rk-base-white), .5);
    background: var(--rk-primary);
    color: var(--rk-base-white) !important;

    &:hover {
      background: var(--rk-secondary-light);
      border: 1px solid var(--rk-secondary-dark);
      color: var(--rk-base-white);
    }

    @media (max-width: var(--rk-viewport-md)) {
      border: 1px solid var(--rk-base-grey-lighter);
      background: var(--rk-base-white);
      color: var(--rk-primary-light);

      &:hover {
        background: var(--rk-secondary-light);
        border: 1px solid var(--rk-secondary-dark);
        color: var(--rk-base-white) !important;
      }
    }
  }

  @include modifier("header-enquire-white") {
    border: 1px solid var(--vprimary-light);
    background: none;
    color: var(--vbase-white);

    @media (max-width: var(--rk-viewport-md)) {
      border: 1px solid var(--rk-base-grey-lighter);
      background: var(--rk-base-white);
      color: var(--rk-primary-light);
    }

    &:hover {
      background: var(--rk-primary);
      border: 1px solid var(--rk-primary);
      color: var(--vbase-white) !important;
    }
  }

  @include modifier("header-enquire-transparent") {
    border: 1px solid var(--rk-base-white);
    background: none;

    @media (max-width: var(--viewport-md)) {
      border: 1px solid var(--rk-base-grey-lighter);
      background: var(--rk-base-white);
      color: var(--rk-primary-light);
    }

    &:hover {
      background: var(--rk-primary);
      border: 1px solid var(--rk-primary);
      color: var(--rk-base-white) !important;
    }
  }

  @include modifier("facet") {
    background: none;
    font-weight: 700;
    color: var(--rk-primary);
    text-transform: none;
    padding: 15px;
    border-radius: 0;
    height: 100%;
    border: none;

    &:hover,
    &:focus {
      background: var(--rk-base-grey-lighter);
      color: var(--rk-primary);
      border: none;
    }
  }

  @include modifier("facet-search") {
    background: none;
    font-weight: 700;
    color: var(--rk-primary);
    text-transform: none;
    padding: 15px;
    border-radius: 0;
    height: 100%;
    border: none;

    &:hover,
    &:focus {
      background: var(--rk-base-grey-lighter);
      color: var(--rk-primary);
      border: none;
    }
  }

  /*------------------------------------------------------------------------------
	// Outlined Buttons
	//----------------------------------------------------------------------------*/
  @include modifier("outlined-primary") {
    border: 1px solid var(--rk-primary-mix-lightest);
    background: var(--rk-base-white);
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border-color: var(--rk-primary-light);
      background: var(--rk-primary-light);
      color: var(--rk-base-white);
    }
  }

  @include modifier("outlined-secondary") {
    border: 1px solid var(--rk-secondary);
    background: var(--rk-base-white);
    color: var(--rk-text);

    &:hover,
    &:focus {
      background: var(--rk-secondary-light);
      border-color: var(--rk-secondary);
      color: var(--rk-base-white);
    }
  }

  @include modifier("carousel") {
    border-radius: 50vw;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 1px solid var(--rk-base-grey);
    background: var(--rk-base-white);

    i {
      font-size: 22px;
      color: var(--rk-primary-light);
    }

    &:hover,
    &:focus {
      background: var(--rk-base-grey-light);

      i {
        color: var(--rk-secondary-light);
      }
    }
  }

  @include modifier("outlined-alternate") {
    border: 1px solid var(--rk-alternate);
    background: var(--rk-base-white);
    color: var(--rk-text);

    &:hover,
    &:focus {
      border-color: var(--rk-alternate-dark);
      background: var(--rk-alternate);
      color: var(--rk-text);
    }
  }

  @include modifier("outlined-gray-no-sides") {
    border-top: 1px solid var(--rk-base-grey);
    border-bottom: 1px solid var(--rk-base-grey);
    background: var(--rk-base-white);
    color: var(--rk-gray);

    &:hover,
    &:focus {
      border-color: var(--rk-gray);
      background: var(--rk-base-grey-lighter);
      color: var(--rk-base-white);
    }
  }

  @include modifier("outlined-gray") {
    border: 1px solid var(--rk-gray);
    background: var(--rk-base-white);
    color: var(--rk-gray);

    &:hover,
    &:focus {
      border-color: var(--rk-gray);
      background: var(--rk-base-grey-light);
      color: var(--rk-base-white);
    }
  }

  @include modifier("outlined-white") {
    border: 1px solid var(--rk-base-white);
    background: transparent;
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      border-color: var(--rk-base-white);
      background: var(--rk-base-white);
      color: var(--rk-primary-light);
    }
  }

  @include modifier("mobile-nav") {
    font-size: 16px;
    padding: 16px;
    text-transform: uppercase;
    font-weight: 700;
    border-top: 1px solid var(--rk-base-grey-light);
  }

  /*------------------------------------------------------------------------------
	// Accordion Buttons
	//----------------------------------------------------------------------------*/
  @include modifier("accordion-main") {
    border: none;

    /*Reset Border*/
    background: var(--rk-base-white);
    text-align: left;
    border-radius: 0;
    padding: var(--rk-p-sm);
    color: var(--rk-text);
    margin: 0;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-secondary);
      outline: none;
    }
  }

  @include modifier("accordion-sub") {
    border: 0;
    background: var(--rk-base-off-white);
    text-align: left;
    border-radius: 0;
    padding: 15px;
    color: var(--rk-text);
    margin: 0 0 15px;
    transition: all .3s ease-in-out;

    &:not(.collapse),
    &:hover {
      background: var(--rk-base-grey);
      outline: none;
      color: var(--rk-text);
    }

    &:focus {
      background: var(--rk-base-off-white);
      color: var(--rk-text);
    }

    .zen-accordion {
      .zen-accordion {
        @include modifier("accordion-sub") {
          background: var(--rk-base-off-white) !important;
          color: var(--rk-text);
        }
      }
    }
  }

  @include modifier("accordion-mobilemenu") {
    position: relative;
    padding: 13.6px 40px 13.5px 10px;

    &:after {
      content: "\e804";
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      color: var(--rk-gray);
      font-family: fontello;
      transform: rotate(0deg);
    }
  }

  @include modifier("flyout-mobilemenu") {
    position: relative;
    padding: 20px;
    color: var(--rk-primary);
    font-weight: 700;
    background: var(--rk-base-white);

    &:after {
      content: "\e802";
      color: var(--rk-secondary);
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      font-family: fontello;
      transform: rotate(0deg);
    }
  }

  /*------------------------------------------------------------------------------
	// Filter Panel
	//----------------------------------------------------------------------------*/
  @include modifier("navigate") {
    border: none;

    /*Reset Border*/
    border-bottom: 1px solid var(--rk-base-grey);
    justify-content: space-between;
    text-transform: uppercase;
    background: var(--rk-base-white);
    align-items: center;
    font-weight: normal;
    margin: 0 0 var(--rk-m-df);
    text-align: unset;
    color: var(--rk-secondary);
    display: flex;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-secondary);
      outline: none;
    }
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/
  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  /*Default btn text size medium*/
  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend button element & modifiers
//----------------------------------------------------------------------------*/

%zen-btn {
  @extend .zen-btn;
}

%zen-btn--secondary {
  @extend .zen-btn--secondary;
}

%zen-btn--alternate {
  @extend .zen-btn--alternate;
}

%zen-btn--opaque {
  @extend .zen-btn--opaque;
}

%zen-btn--default {
  @extend .zen-btn--default;
}

%zen-btn--warning {
  @extend .zen-btn--warning;
}

%zen-btn--danger {
  @extend .zen-btn--danger;
}

%zen-btn--header-enquire-white {
  @extend .zen-btn--header-enquire-white;
}

%zen-btn--header-enquire-transparent {
  @extend .zen-btn--header-enquire-transparent;
}

%zen-btn--header-enquire-blue {
  @extend .zen-btn--header-enquire-blue;
}

%zen-btn--dark {
  @extend .zen-btn--dark;
}

%zen-btn--outlined-primary {
  @extend .zen-btn--outlined-primary;
}

%zen-btn--outlined-secondary {
  @extend .zen-btn--outlined-secondary;
}

%zen-btn--full-size {
  @extend .zen-btn--full-size;
}

%zen-btn--accordion-main {
  @extend .zen-btn--accordion-main;
}

%zen-btn--accordion-sub {
  @extend .zen-btn--accordion-sub;
}
