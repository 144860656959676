/*==============================================================================
// File:        _picture.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared picture styling component - SASS
//============================================================================*/

.picture-wrapper {
  position: relative;

  .picture-main {
    max-width: 100%;
  }

  .picture-effect {
    position: absolute;
    max-width: 100%;
    opacity: .1;
  }

  .picture-effect--left {
    top: 30px;
    right: 30px;
    z-index: -10;
    left: unset;
  }

  .picture-effect--right {
    top: 30px;
    left: 30px;
    z-index: -10;
    right: unset;
  }

  @media (max-width: $viewport-md) {
    .picture-effect--left {
      top: 10px;
      right: 10px;
    }

    .picture-effect--right {
      top: 10px;
      left: 10px;
    }
  }
}
