/*==============================================================================
// File:        _body.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared body component - SASS
//============================================================================*/

.zen-body {
  z-index: var(--rk-z-index-bottom);
  position: relative;
  padding: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("main") {
    padding: 0;
  }

  @include element("breadcrumbs") {
    padding: 0;
    font-family: var(--rk-secondary-font);

    @include media-breakpoint-down(md) {
      border-top: 1px solid var(--rk-base-grey-lighter);
    }
  }
}
