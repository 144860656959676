/*==============================================================================
// File:        _hotel.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared hotel component - SASS
//============================================================================*/

.zen-hotel {
  .zen-hero--hotel {
    .hero-image:after {
      content: "";
      position: absolute;
      inset: 0;
      background: rgba($primary, .4);
      z-index: 0;
    }

    .hero-image {
      min-height: 600px;
      background-size: cover;
    }

    .hero-inner {
      z-index: 1;
    }
  }

  @include element("image-grid") {
    display: grid;
    grid-template-columns: .2fr repeat(2, 1fr) .1fr repeat(3, 1fr);
    grid-template-rows: .2fr .5fr 1.5fr .2fr 1.5fr .5fr .1fr;
    grid-gap: 0;
    height: 70vh;
    min-height: 70vw;
    margin-top: -80px;
    margin-bottom: 80px;

    @media (max-width: 992px) {
      height: 20vw;
      margin-top: -80px;
      margin-bottom: 0;
    }

    .grid-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    .image-1 {
      grid-area: 2 / 2 / 4 / 4;
    }

    .image-2 {
      grid-area: 5 / 2 / 7 / 4;
    }

    .image-3 {
      grid-area: 3 / 5 / 6 / 8;
    }
  }

  @include element("wrapper") {
    scroll-margin-top: 250px;
  }

  @include element("intro") {
    margin-top: 40px;

    .zen-review {
      background-color: var(--rk-base-off-white);
      border-left: 3px solid var(--rk-secondary-light);
      padding: 30px;
      margin-top: 40px;
      margin-bottom: 40px;

      &__content {
        position: relative;
        text-transform: none;
        font-size: 18px;
        font-weight: 200;
        font-style: italic;
        color: var(--rk-secondary);

        &:before {
          content: "“";
          font-size: 40px;
          color: var(--rk-primary);
          position: absolute;
          top: -20px;
          left: -20px;
        }

        &:after {
          content: "”";
          font-size: 40px;
          color: var(--rk-primary);
          position: absolute;
          bottom: -20px;
          right: 0;
        }
      }

      &__image {
        border-radius: 50vw;
        width: 100px;
      }
    }
  }

  @include element("villas") {
    padding: 80px 0 80px 80px;
    margin: 0 0 0 auto;
    scroll-margin-top: 80px;

    @media (max-width: var(--viewport-md)) {
      padding: 0 30px;
    }
  }

  @include element("image-section") {
    max-width: 90vw;
    padding: 80px 0;
    margin: 0 0 0 auto;

    @media (max-width: var(--viewport-md)) {
      padding: 80px 0 80px 20px;
      max-width: 95vw;
    }
  }

  @include element("food") {
    max-width: 90vw;
    padding: 80px 0 80px 80px;
    margin: 0 0 0 auto;
    scroll-margin-top: 80px;

    @media (max-width: var(--viewport-md)) {
      padding: 80px 0 80px 20px;
      max-width: 95vw;
    }
  }

  @include element("facilities-item") {
    background: var(--rk-base-off-white);
    border: 1px solid var(--rk-base-grey);
  }

  @include element("facilities") {
    scroll-margin-top: 80px;
  }

  .view-all-properties {
    width: 100%;
    margin: 48px auto 0;
    display: block;
    max-width: 400px;
    padding: 15px 20px 14px;
    text-align: center;
  }
}
