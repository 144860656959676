/*==============================================================================
// File:        _datepicker.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Date Picker overrides - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
 * Stylesheet for the Date Range Picker, for use with Bootstrap 3.x
 *
 * Copyright 2013 Dan Grossman ( http://www.dangrossman.info )
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Built for http://www.improvely.com
/-----------------------------------------------------------------------------*/

.daterangepicker .btn-sm {
  width: 100%;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
  padding: 18px;
}

.daterangepicker.opensleft .calendar,
.daterangepicker.opensleft .ranges {
  float: left;
  margin: 4px;
}

.daterangepicker.openscenter .calendar,
.daterangepicker.opensright .calendar,
.daterangepicker.openscenter .ranges,
.daterangepicker.opensright .ranges {
  float: right;
  margin: 4px;
}

.daterangepicker.single .calendar,
.daterangepicker.single .ranges {
  float: none;
}

.daterangepicker .ranges {
  margin-right: 18px !important;
  text-align: left;
  width: 165px;
}

.daterangepicker .ranges .range_inputs > div {
  margin: 0 0 25px;
  float: left;
}

.daterangepicker .ranges .range_inputs > div:nth-child(2) {
  padding-left: 11px;
}

.daterangepicker .calendar {
  max-width: 270px;
  display: none;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-date {
  border: none;
}

.daterangepicker .calendar th,
.daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
}

.daterangepicker .daterangepicker_start_input label,
.daterangepicker .daterangepicker_end_input label {
  text-shadow: var(--rk-base-white) 1px 1px 0;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 2px;
  line-height: 20px;
  font-size: 11px;
  display: block;
  height: 20px;
  color: #333;
  width: 74px;
}

.daterangepicker .ranges input {
  font-size: 11px;
}

.daterangepicker .ranges .input-mini {
  vertical-align: middle;
  border: 1px solid #CCC;
  border-radius: 4px;
  margin: 0 0 10px;
  line-height: 30px;
  font-size: 11px;
  padding: 0 6px;
  display: block;
  height: 30px;
  width: 74px;
  color: #555;
}

.daterangepicker .ranges ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.daterangepicker .ranges li {
  border: 1px solid var(--rk-primary);
  background: var(--rk-primary);
  margin-bottom: 8px;
  border-radius: 5px;
  padding: 3px 12px;
  font-size: 13px;
  cursor: pointer;
  color: var(--rk-primary);
}

.daterangepicker .ranges li.active {
  border: 1px solid var(--rk-primary);
  background: var(--rk-primary);
  color: var(--rk-base-white);
}

.daterangepicker .ranges li:hover {
  border: 1px solid var(--rk-secondary);
  background: var(--rk-secondary);
  color: var(--rk-base-white);
}

.daterangepicker .ranges li.zen-filter-next {
  color: var(--rk-base-white);
}

.daterangepicker .calendar-time {
  margin: 8px auto 0;
  text-align: center;
  line-height: 30px;
}

.daterangepicker {
  background: var(--rk-base-white);
  border-radius: 4px;
  position: absolute;
  margin-top: 1px;
  padding: 4px;
  top: 100px;
  left: 20px;
}

.daterangepicker.opensleft:before {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  display: inline-block;
  position: absolute;
  content: "";
  right: 9px;
  top: -7px;
}

.daterangepicker.opensleft:after {
  border-bottom: 6px solid var(--rk-base-white);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  display: inline-block;
  position: absolute;
  right: 10px;
  content: "";
  top: -6px;
}

.daterangepicker.openscenter:before {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  display: inline-block;
  margin-right: auto;
  position: absolute;
  margin-left: auto;
  content: "";
  top: -7px;
  right: 0;
  width: 0;
  left: 0;
}

.daterangepicker.openscenter:after {
  border-bottom: 6px solid var(--rk-base-white);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  display: inline-block;
  margin-right: auto;
  position: absolute;
  margin-left: auto;
  content: "";
  top: -6px;
  right: 0;
  width: 0;
  left: 0;
}

.daterangepicker.opensright:before {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  display: inline-block;
  position: absolute;
  content: "";
  top: -7px;
  left: 9px;
}

.daterangepicker.opensright:after {
  border-bottom: 6px solid var(--rk-base-white);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  display: inline-block;
  position: absolute;
  content: "";
  left: 10px;
  top: -6px;
}

.daterangepicker table {
  width: 100%;
  margin: 0;
}

.daterangepicker td,
.daterangepicker th {
  white-space: nowrap;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.daterangepicker td.off {
  color: #999;
}

.daterangepicker option.disabled,
.daterangepicker td.disabled {
  color: #999;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: var(--rk-primary);
  color: var(--rk-base-white);
}

.daterangepicker td.in-range {
  background: #FEF5E3;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active:hover,
.daterangepicker td.active {
  background-color: var(--rk-primary);
  border-color: var(--rk-primary);
  color: var(--rk-base-white);
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #CCC;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  cursor: default;
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.hourselect,
.daterangepicker select.ampmselect {
  margin-bottom: 0;
  width: 50px;
}

.daterangepicker_start_input {
  float: left;
}

.daterangepicker_end_input {
  padding-left: 11px;
  float: left;
}

.daterangepicker th.month {
  width: auto;
}

@include media-breakpoint-down(sm) {
  .daterangepicker.show-calendar .calendar {
    display: none;
  }
}

.daterangepicker .applyBtn {
  color: var(--rk-primary);
}
