/*==============================================================================
// File:        _title.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared title component - SASS
//============================================================================*/

.zen-title {
  padding: 0;
  font-family: var(--rk-primary-font);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--rk-primary-font);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("location") {
    font-family: var(--rk-location-title-font);
  }

  @include modifier("default") {
    color: var(--rk-default);
  }

  @include modifier("light") {
    color: var(--rk-base-white);

    .sppb-addon-title {
      color: var(--rk-base-white);
    }
  }

  @include modifier("dark") {
    color: var(--rk-text);

    .sppb-addon-title {
      color: var(--rk-text);
    }
  }

  @include modifier("primary") {
    color: var(--rk-primary-light);

    .sppb-addon-title {
      color: var(--rk-primary-light);
    }
  }

  @include modifier("secondary") {
    color: var(--rk-secondary-light);

    .sppb-addon-title {
      color: var(--rk-secondary-light);
    }
  }

  @include modifier("hr-primary") {
    margin: var(--rk-m-full-xl);

    &:after {
      @include pseudo;

      border-bottom: 1px solid var(--rk-primary);
      margin: var(--rk-m-df) auto;
      width: 100px;
      right: 0;
      left: 0;
    }
  }

  @include modifier("hero-main") {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--rk-base-white);
      text-shadow: 0 0 5px rgba(0, 0, 0, 50%), 0 0 20px rgba(0, 0, 0, 50%);
      font-family: var(--rk-primary-font);
      font-weight: 700;
    }

    i {
      font-weight: normal;
      font-style: italic;
    }
  }

  @include modifier("hero-subheading") {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--rk-base-white);
      text-shadow: 0 0 5px rgba(0, 0, 0, 50%), 0 0 10px rgba(0, 0, 0, 50%);
      text-transform: none;
      font-weight: normal;
      font-family: var(--rk-primary-font);
    }

    i {
      font-weight: normal;
      font-style: italic;
    }
  }

  @include modifier("border-left") {
    border-left: 5px solid;
    padding-left: 15px;
  }

  @include modifier("secondary-font") {
    font-family: var(--rk-secondary-font);

    .sppb-addon-title {
      font-family: var(--rk-secondary-font);
    }
  }

  @include modifier("category") {
    @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 18px, 21px);

    border-bottom: 1px solid var(--rk-secondary);
    padding: 0 0 var(--rk-p-sm);
    margin: var(--rk-m-full-md);
    font-weight: bold;
    color: var(--rk-secondary);
  }

  @include modifier("booking") {
    letter-spacing: normal;
    margin: var(--rk-m-sm) 0 var(--rk-m-xs);
    text-transform: none;
    font-style: italic;
    color: var(--rk-text);
  }
}

/*------------------------------------------------------------------------------
// Extend title element & modifiers
//----------------------------------------------------------------------------*/

%zen-title {
  @extend .zen-title;
}

%zen-title--light {
  @extend .zen-title--light;
}

%zen-title--dark {
  @extend .zen-title--dark;
}

%zen-title--primary {
  @extend .zen-title--primary;
}

%zen-title--secondary {
  @extend .zen-title--secondary;
}

%zen-title--category {
  @extend .zen-title--category;
}
