/*==============================================================================
// File:        _card.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared card component - SASS
//============================================================================*/

.zen-card {
  border: 1px solid var(--rk-base-grey);
  background: var(--rk-base-white);
  border-radius: 0;
  margin: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("image-container") {
    padding: var(--rk-p-df);
  }

  @include element("image") {
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 0;
    margin: 0;

    @include modifier("max-height") {
      max-height: 285px;
      overflow: hidden;
    }
  }

  @include element("title") {
    margin: $m-md 0;
  }

  @include element("header") {
    background: var(--rk-light);
    border-radius: 0;
    border: none;
  }

  @include element("body") {
    padding: var(--rk-p-df);
  }

  @include element("content") {
    padding: var(--rk-p-sm);

    ul {
      @extend %zen-list;
      @extend %zen-list--default;
    }
  }

  @include element("description") {
    padding: var(--rk-p-df);
  }

  @include element("info") {
    border-bottom: 1px solid var(--rk-base-grey-lighter);
    border-top: 1px solid var(--rk-base-grey-lighter);
    text-transform: capitalize;
    margin: 0 var(--rk-m-df) var(--rk-m-df);
    padding: var(--rk-p-df);
  }

  @include element("price") {
    justify-content: space-between;
    background: var(--rk-alternate-light);
    border: 1px solid var(--rk-alternate);
    margin: 0 var(--rk-m-df);
    padding: var(--rk-p-df);
    display: flex;
  }

  @include element("footer") {
    background: var(--rk-base-white);
    text-align: center;
    padding: var(--rk-p-df);
    border: none;
  }

  /*==============================================================================
    // Modifiers
    //============================================================================*/

  @include modifier("related") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    margin: 0 auto var(--rk-m-xl);
    max-width: 435px;

    &:focus,
    &:hover {
      @include insert-shadow(0, 0, 15px, -2px, var(--rk-shadow));

      cursor: pointer;
    }
  }

  @include modifier("search") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    &:focus,
    &:hover {
      @include insert-shadow(0, 0, 15px, -2px, var(--rk-shadow));

      cursor: pointer;
    }
  }

  @include modifier("exclusive") {
    border: 1px solid var(--rk-primary);
  }

  @include modifier("note") {
    padding: var(--rk-p-lg) var(--rk-p-xl);
  }

  @include modifier("full-height") {
    display: block;
    display: grid;
    height: 100%;
  }

  @include modifier("dates-prices") {
    border: 6px solid var(--rk-alternate);
    background: var(--rk-base-white);
  }

  @include modifier("dates-prices-alt") {
    background: var(--rk-alternate);
  }
}

/*------------------------------------------------------------------------------
// SPPB Addon for Property Card
//----------------------------------------------------------------------------*/

.zen-property-card {
  text-decoration: none;

  &:hover {
    .zen-property-card__inner {
      border: 1px solid var(--rk-base-grey);
      transform: scale(1.01);
    }
  }

  @include element("recommended-wrapper") {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    background: rgba(255, 255, 255, 80%);
    backdrop-filter: blur(10px);
    border-radius: 50vw;
  }

  @include element("recommended-text") {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    color: var(--rk-base-black);
  }

  @include element("inner") {
    box-sizing: border-box;
    transition: all .3s ease-in-out;
    border: 1px solid var(--rk-base-off-white);
  }

  @include element("content") {
    padding: 15px;
    background: var(--rk-base-off-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include element("property-name") {
    font-family: var(--rk-primary-font);
    font-weight: 700;
    margin-bottom: 0;
    font-size: 19px !important;
    color: var(--rk-base-black);

    span {
      font-family: var(--rk-primary-font);
    }
  }

  @include element("location-name") {
    color: var(--rk-base-black);
    margin: 0 0 0 5px;
    font-weight: bold;
  }

  @include element("image-wrapper") {
    background: var(--rk-base-grey-lighter);
    position: relative;
    height: 300px;
    background-size: cover;
    background-position: center;
    transition: all .1s ease-in-out;
    background-repeat: no-repeat;
  }

  @include element("image") {
    max-width: 100%;
    aspect-ratio: auto 1/1;
  }

  @include element("rating") {
    color: var(--rk-gold);
    margin: 0;
    padding: 0;
    font-weight: bold;
    position: relative;
    top: 2px;
  }

  @include element("humclub-logo") {
    max-width: 70px;
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-card {
  @extend .zen-card;
}

%zen-card__image {
  @extend .zen-card__image;
}

%zen-card__title {
  @extend .zen-card__title;
}

%zen-card__header {
  @extend .zen-card__header;
}

%zen-card__body {
  @extend .zen-card__body;
}

%zen-card__info {
  @extend .zen-card__info;
}

%zen-card__footer {
  @extend .zen-card__footer;
}
