.zen-slider-marquee .sppb-column-addons {
  display: flex;
  gap: 30px;
  white-space: nowrap;
  position: relative;
  width: 100%;
  overflow: hidden;
  flex-wrap: nowrap;
}

.slider-first,
.slider-second {
  animation-name: marquee;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: translate3d(0%, 0, 0);
  animation-duration: 36s;
  width: 100%;
}

.zen-slider-marquee:hover .slider-first,
.zen-slider-marquee:hover .slider-second {
  animation-play-state: paused !important;
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
