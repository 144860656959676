.zen-linkbar {
  border-bottom: 1px solid var(--rk-base-grey);
  position: sticky;
  top: 0;
  width: 100%;
  background: rgba($base-white, .8);
  backdrop-filter: blur(5px);
  z-index: var(--rk-z-index-top);
  overflow: scroll;
  margin-top: 30px;

  &::-webkit-scrollbar {
    display: none;
  }

  .nav-link {
    text-transform: uppercase;
    display: inline-block;
    padding: 20px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    color: var(--rk-primary-light);
    position: relative;

    &:after {
      content: "";
      height: 5px;
      position: absolute;
      background: var(--rk-secondary-light);
      bottom: 0;
      left: 0;
      right: 0;
      width: 0%;
      transition: all .2s ease-in-out;
    }

    &.active {
      color: var(--rk-secondary-light);

      &:after {
        width: 100%;
      }
    }

    &:hover {
      color: var(--rk-primary-light);
    }
  }

  @include modifier("location") {
    border-top: 1px solid var(--rk-base-grey);
  }
}

.scrolled .zen-linkbar {
  top: 95px;
  position: fixed;
  margin-top: 0;
}

.scrolled.zen-linkbar--location {
  top: 95px;
  position: fixed;
  margin-top: 0;
}
