/*==============================================================================
// File:        _hero.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared hero component - SASS
//============================================================================*/

.zen-hero {
  position: relative;

  .hero-image {
    height: 700px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }

  .hero-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba($base-black, .4) 10%, rgba($base-black, 0) 40%);
  }

  .hero-inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h3 {
      font-style: italic;
    }

    h1,
    h3 {
      text-shadow:
        0 0 5px rgba($base-black, .5),
        0 0 20px rgba($base-black, .5);
    }

    @include media-breakpoint-up(lg) {
      h3 {
        font-style: italic;
        font-size: 24px;
      }

      h1 {
        font-size: 64px;
      }
    }
  }
}
