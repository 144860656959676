/*==============================================================================
// File:        _footer.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main footer styles for the website - SASS
//============================================================================*/

.zen-footer {
  background: var(--rk-primary);
  z-index: var(--rk-z-index-bottom);
  position: relative;

  @include element("socials") {
    background: var(--rk-primary);
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("col") {
    @media (min-width: var(--rk-viewport-md)) {
      border-left: 1px solid rgba(var(--rk-base-white), .2);
    }

    .nav-item .mod-menu__heading,
    h6 {
      color: var(--rk-secondary-light);
      font-size: var(--rk-font-size-lg);
      margin-bottom: var(--rk-m-xl);
      font-family: var(--rk-secondary-font);
      font-weight: 700;
      text-transform: uppercase;
    }

    .nav-item a {
      color: var(--rk-base-white);
      margin-bottom: var(--rk-m-md);
      font-size: var(--rk-font-size-md);
    }

    .sppb-addon-text-block {
      color: var(--rk-base-white);
    }
  }

  @include element("copyright") {
    font-size: var(--rk-font-size-sm);
    background: var(--rk-primary);
    text-align: center;
    padding: var(--rk-p-df) var(--rk-p-df) var(--rk-p-lg) var(--rk-p-df);
  }
}
