/*==============================================================================
// File:        _form.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared form component - SASS
//============================================================================*/

.zen-form {
  position: relative;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--rk-text);
  }

  textarea {
    width: 100%;
    height: 200px;
    border-color: #CCC;
    color: var(--rk-primary);
  }

  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-color: #CCC;
    color: var(--rk-primary);
    background: var(--base-white);
  }

  .g-recaptcha {
    margin-bottom: 20px;
  }

  #insightly_Which_destinations_are_you_interested_in__c {
    height: 110px;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #CCC;
    color: var(--rk-primary);
  }

  input[type="checkbox"] {
    display: flex;
    align-items: start;
    justify-content: start;
    width: 20px;
  }

  input[type="submit"] {
    @extend %zen-btn;

    border: none;
  }
}
