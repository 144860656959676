/*==============================================================================
// File:        _cta.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared cta component - SASS
//============================================================================*/

.zen-cta {
  position: relative;
  padding: var(--rk-p-df);
  color: var(--rk-text);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("text") {
    color: var(--rk-text);
  }

  /*------------------------------------------------------------------------------
	// Mr Zen SPPB Feature Box
	//----------------------------------------------------------------------------*/

  @include element("featurebox-image") {
    @include flex--center;
  }

  @include element("featurebox-icon") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 100px, 140px);

    color: var(--rk-primary);
  }

  @include element("featurebox-content") {
    padding: var(--rk-p-sm) var(--rk-p-md) var(--rk-p-lg);
    min-height: 140px;
  }

  /*------------------------------------------------------------------------------
	// Mr Zen SPPB Feature Button
	//----------------------------------------------------------------------------*/

  @include element("featurebutton-image") {
    margin: 0 var(--rk-m-df) 0 0;
  }

  @include element("featurebutton-icon") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 30px, 70px);

    margin: 0 var(--rk-m-df) 0 0;
    line-height: 1em;
    color: var(--rk-primary);
  }

  @include element("featurebutton-title") {
    @include fluid-prop(font-size, var(--rk-viewport-full-min), var(--rk-viewport-xl), 14px, 24px);

    font-family: var(--rk-primary-font);
    text-transform: uppercase;
    color: var(--rk-text);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("person") {
    .zen-cta__inner {
      padding: 10px;
      background: var(--rk-base-white);
    }

    .zen-cta__image {
      height: 300px;
      background-size: cover;
      background-position: center;
      background-color: var(--rk-base-white);
      transition: .4s all;
    }
  }

  // Alternative to Bootstraps p-0.
  //
  @include modifier("no-pad") {
    padding: 0;
  }

  @include modifier("btn") {
    display: inline-block;
    text-align: center;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @include modifier("featurebox") {
    text-align: center;
    padding: 0;
  }

  @include modifier("featurebutton") {
    @include flex--center;
    @include insert-shadow(0, 0, 10px, -2px, var(--rk-shadow));

    border-top-right-radius: var(--rk-border-radius-df);
    border-top-left-radius: var(--rk-border-radius-df);
    border: 1px solid var(--rk-base-grey-light);
    border-bottom: 6px solid var(--rk-primary);
    text-align: center;
    margin: 0 0 var(--rk-m-xl);
    padding: var(--rk-p-sm);
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend cta element & modifiers
//----------------------------------------------------------------------------*/

%zen-cta {
  @extend .zen-cta;
}
