/*==============================================================================
// File:        _sppb.scss
// Package:     Joomla / Mr Zen
// Synopsis:    SP Page Builder overrides - SASS
//============================================================================*/

// Text Block
.sppb-addon-text-block {
  font-family: $secondary-font;
}

// Carousel Slider
.sppb-carousel-content {
  font-family: $secondary-font;
}

.sppb-testimonial-carousel-testi_layout2 {
  .sppb-carousel-extended-item {
    margin: 0 auto;
    padding: 30px;
  }
}

.sppb-carousel-extended {
  .sppb-carousel-extended-item {
    .sppb-testimonial-carousel-item-content {
      max-width: 960px;
      margin: 0 auto !important;
    }

    .sppb-testimonial-carousel-name {
      color: $secondary-light !important;
      border-bottom: 2px solid;
      padding-bottom: 15px;
      margin-bottom: 30px !important;
    }

    .sppb-testimonial-carousel-message {
      font-size: 27px !important;
      line-height: 45px !important;
      line-height: auto;
      font-weight: 200;
      color: $base-white !important;
      position: relative;
      font-style: italic;

      &:before {
        content: "“";
        color: #BA922C;
        position: absolute;
        left: -20px;
        font-size: 90px;
      }

      &:after {
        content: "”";
        color: #BA922C;
        position: absolute;
        right: -20px;
        bottom: -30px;
        font-size: 60px;
      }

      @include media-breakpoint-down(md) {
        font-size: 20px !important;
      }
    }
  }

  .sppb-carousel-extended-nav-control {
    .nav-control {
      border-color: $base-grey;
      color: $base-grey;
    }
  }
}

.sppb-addon-simple-card {
  .zen-cta {
    padding: 0;
  }

  .zen-cta__inner {
    position: relative;
    overflow: hidden;
  }

  .zen-cta__image {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      transition: all .3s ease-in-out;
    }
  }

  .zen-cta__title {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $primary-font;
    font-weight: 700;
    color: $base-white;
    flex-direction: column;
    font-size: 2.2rem;
    text-shadow: 0 0 10px rgba(0, 0, 0, 60%);

    &:after {
      content: "\e820";
      font-family: fontello;
      color: $base-white;
      text-shadow: 0 0 10px rgba(0, 0, 0, 60%);
      border: 2px solid $base-white;
      border-radius: 50vw;
      padding: 5px;
      display: inline-block;
      margin-top: 15px;
      font-size: 32px;
      height: 50px;
      width: 50px;
    }
  }

  &:hover {
    .zen-cta__image img {
      transform: scale(1.1);
    }
  }
}

.sppb-addon-feature {
  cursor: pointer;

  .sppb-img-responsive {
    width: 100%;
    height: 400px;
  }

  .sppb-img-container {
    a {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  .sppb-media-content {
    position: absolute;
    top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--rk-base-white);
    left: 0;
    right: 0;
  }

  .sppb-feature-box-title a {
    color: white;
    text-decoration: none;
    font-family: $primary-font;
    font-size: 40px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 90%);
  }

  .sppb-feature-box-title a:after {
    content: "\e820";
    font-family: fontello;
    color: var(--rk-base-white);
    border: 2px solid var(--rk-base-white);
    border-radius: 50vw;
    padding: 5px;
    display: block;
    font-size: 32px;
    width: 50px;
    height: 50px;
    text-align: center;
    left: 0;
    right: 0;
    position: absolute;
    margin: 20px auto;
  }
}

.sppb-modal-selector {
  span {
    border-style: none !important;
  }
}

// Accordion Overrides
.sppb-panel-modern {
  margin-bottom: 20px !important;

  .sppb-panel-heading {
    @extend %zen-btn--accordion-sub;

    margin: 0;

    .sppb-panel-title {
      text-transform: uppercase;
      font-size: 17px;
      padding-left: 15px;
    }

    &:before,
    &.active:before {
      font-family: fontello;
      font-size: 20px;
    }

    &:before {
      content: "\e85c";
    }

    &.active:before {
      content: "\e86a";
    }
  }

  .sppb-panel {
    .sppb-panel-body {
      @extend %p;

      color: $default;
    }
  }
}

// Extending P Style onto SPPB
.sppb-addon-text-block p {
  @extend %p;
}

.sppb-panel-modern > .sppb-panel-heading {
  background: $primary;
  color: $base-white;
}

.sppb-alert-primary {
  background: $primary;
  color: $base-white;
}

.sppb-progress-bar {
  background: $primary;
}

.animated-text-words-wrapper {
  color: $primary;
}

.alert-warning {
  color: $warning;
}

.sppb-blocknumber-number {
  background-color: $primary;
}

.sppb-addon-flickr .sppb-flickr-gallery li a:before {
  background: $secondary;
}

.sppb-addon-flickr .sppb-flickr-gallery li a:hover:before {
  background: transparent;
}

.image-effect-zoom-in {
  cursor: pointer;
}

.pricelist-left-image {
  img {
    max-width: 100%;
  }
}

/*------------------------------------------------------------------------------
    // Fix SP Page Builder Breakpoint Issue
    //----------------------------------------------------------------------------*/

@media screen and (max-width: 768px) and (min-width: 768px) {
  .sppb-addon-image-content {
    .sppb-col-sm-6 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

/*------------------------------------------------------------------------------
// Fix for adding a new row - SPPB front-end edit mode
//----------------------------------------------------------------------------*/

.sppb-row-bottom-new-row .open .sp-pagebuilder-dropdown-row-layouts {
  display: block;
}

/*------------------------------------------------------------------------------
// Adjustment for home page image layout
//----------------------------------------------------------------------------*/

.image-layout-preset-style-poster {
  .sppb-addon-image-layout-content {
    inset: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sppb-image-layout-title {
    font-family: $primary-font;
    font-weight: 400;
    position: relative;
    width: 100%;
    text-shadow: 0 0 10px rgba($base-black, .6);

    &:after {
      @extend %fontello;

      content: "\e820";
      color: $base-white;
      border: 2px solid $base-white;
      border-radius: 50vw;
      padding: 5px;
      display: inline-block;
      margin-top: 15px;
    }
  }

  .sppb-image-layout-title a {
    color: $base-white;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 60px;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $viewport-md) {
      font-size: 48px;
    }
  }
}

.sppb-addon.sppb-addon-text-block .sppb-addon-content .zen-list li {
  padding-left: 10px;

  &::marker {
    content: "\e815";
    font-family: fontello;
    color: var(--rk-secondary-light);
  }
}

// Styling for 'Affilliates' carousel images on homepage footer/hero
.sppb-carousel-extended-item img {
  width: 180px !important;
  height: auto !important;
}

/*------------------------------------------------------------------------------
// Zen SP Page Builder Overrides
//
// Example changing the standard spacing to 20px
// Setting all items to maximum width up to 1440px
//----------------------------------------------------------------------------*/

// Margin top to give space for breadcrumbs
html.homepage #sp-page-builder {
  margin-top: 0 !important;
}

html.subpage #sp-page-builder {
  margin-top: 125px;
}

.sppb-row-container {
  padding-right: 15px;
  padding-left: 15px;

  @include media-breakpoint-up(sm) {
    max-width: 760px;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    max-width: 900px;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1100px;
    width: 100%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1600px;
    width: 100%;
  }
}

.sp-pagebuilder-row,
.sppb-row {
  margin-right: -15px;
  margin-left: -15px;

  @include adjoin("sppb-no-gutter") {
    margin-right: 0;
    margin-left: 0;
  }
}

.sppb-col,
.sppb-col-auto,
.sppb-col-lg,
.sppb-col-lg-1,
.sppb-col-lg-10,
.sppb-col-lg-11,
.sppb-col-lg-12,
.sppb-col-lg-2,
.sppb-col-lg-3,
.sppb-col-lg-4,
.sppb-col-lg-5,
.sppb-col-lg-6,
.sppb-col-lg-7,
.sppb-col-lg-8,
.sppb-col-lg-9,
.sppb-col-lg-auto,
.sppb-col-md,
.sppb-col-md-1,
.sppb-col-md-10,
.sppb-col-md-11,
.sppb-col-md-12,
.sppb-col-md-2,
.sppb-col-md-3,
.sppb-col-md-4,
.sppb-col-md-5,
.sppb-col-md-6,
.sppb-col-md-7,
.sppb-col-md-8,
.sppb-col-md-9,
.sppb-col-md-auto,
.sppb-col-sm,
.sppb-col-sm-1,
.sppb-col-sm-10,
.sppb-col-sm-11,
.sppb-col-sm-12,
.sppb-col-sm-2,
.sppb-col-sm-3,
.sppb-col-sm-4,
.sppb-col-sm-5,
.sppb-col-sm-6,
.sppb-col-sm-7,
.sppb-col-sm-8,
.sppb-col-sm-9,
.sppb-col-sm-auto,
.sppb-col-xl,
.sppb-col-xl-1,
.sppb-col-xl-10,
.sppb-col-xl-11,
.sppb-col-xl-12,
.sppb-col-xl-2,
.sppb-col-xl-3,
.sppb-col-xl-4,
.sppb-col-xl-5,
.sppb-col-xl-6,
.sppb-col-xl-7,
.sppb-col-xl-8,
.sppb-col-xl-9,
.sppb-col-xl-auto,
.sppb-col-xs-1,
.sppb-col-xs-10,
.sppb-col-xs-11,
.sppb-col-xs-12,
.sppb-col-xs-2,
.sppb-col-xs-3,
.sppb-col-xs-4,
.sppb-col-xs-5,
.sppb-col-xs-6,
.sppb-col-xs-7,
.sppb-col-xs-8,
.sppb-col-xs-9 {
  padding-right: 15px;
  padding-left: 15px;
}

/*------------------------------------------------------------------------------
    // Button Module and Class Overrides - Mr Zen Customisations
    //----------------------------------------------------------------------------*/

.sppb-btn {
  @extend %zen-btn;

  @include adjoin("sppb-btn-default") {
    @extend %zen-btn;
  }

  @include adjoin("sppb-btn-primary") {
    @extend %zen-btn--outlined-primary;
  }

  @include adjoin("sppb-btn-secondary") {
    @extend %zen-btn--secondary;
  }

  @include adjoin("sppb-btn-success") {
    @extend %zen-btn--outlined-secondary;
  }

  @include adjoin("sppb-btn-info") {
    @extend %zen-btn--default;
  }

  @include adjoin("sppb-btn-warning") {
    @extend %zen-btn--warning;
  }

  @include adjoin("sppb-btn-danger") {
    @extend %zen-btn--danger;
  }

  @include adjoin("sppb-btn-dark") {
    @extend %zen-btn--dark;
  }

  @include adjoin("sppb-btn-link") {
    @extend %zen-btn--opaque;
  }
}

/*------------------------------------------------------------------------------
    // Slideshow Module - Mr Zen Customisations
    //----------------------------------------------------------------------------*/

.sppb-sp-slider-button .sp-slider-btn-text {
  @extend %zen-btn;
}

.sp-slider .sp-dots,
.sp-slider .sp-nav-control {
  @include media-breakpoint-down(md) {
    height: 40px;
  }
}

.sppb-addon-sp-slider .sp-item .sp-background:after {
  background: rgba($base-black, .1);
}

div.sp-slider .sp-nav-control .nav-control {
  background: rgba($base-white, .15);
}

div[data-arrow-content="icon_only"].sp-slider .sp-nav-control .nav-control {
  font-size: 30px;
  height: 60px;
  width: 60px;
}

div[data-arrow-content="icon_only"] .sp-nav-control .nav-control i {
  line-height: 58px;
}

.sp-slider .sp-nav-control .nav-control.next-control i {
  margin: 0 -2px 0 0;
  right: 5px;
}

.sp-slider .sp-nav-control .nav-control.prev-control i {
  margin: 0 0 0 -2px;
  left: 5px;
}

.sp-dots {
  ul li:before {
    content: none;
  }
}

/*------------------------------------------------------------------------------
    // Carousel Pro Overrides - Mr Zen Customisations
    //----------------------------------------------------------------------------*/

.sppb-carousel-pro-text,
.sppb-carousel-text {
  h2 {
    text-shadow: 1px 1px 10px $base-black;
  }

  .sppb-carousel-pro-content {
    text-shadow: 1px 1px 5px $base-black;
  }
}

/*------------------------------------------------------------------------------
    // Image Carousel Overrides - Mr Zen Customisations
    //----------------------------------------------------------------------------*/

.sppb-carousel-extended-dots ul li span {
  background: $primary;
}

/*------------------------------------------------------------------------------
    // Title Style Overrides - Mr Zen Customisations
    //----------------------------------------------------------------------------*/

.sppb-sp-slider-title {
  text-shadow: 1px 1px 10px $base-black;
  margin: $m-full-df;

  @include media-breakpoint-down(sm) {
    padding: 0 15%;
  }
}

.sppb-sp-slider-text {
  text-shadow: 1px 1px 5px $base-black;

  @include media-breakpoint-down(sm) {
    padding: 0 15%;
  }
}

h1.sppb-sp-slider-title {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 38px, 48px);
}

.zen-heading-light .sppb-addon-title {
  @extend %zen-title--light;
}

.zen-heading-dark .sppb-addon-title {
  @extend %zen-title--dark;
}

.zen-heading-primary .sppb-addon-title {
  @extend %zen-title--primary;
}

.zen-heading-secondary .sppb-addon-title {
  @extend %zen-title--secondary;
}

.zen-capitalize .sppb-addon-title {
  text-transform: capitalize;
}

.sppb-addon-header {
  position: relative;
}

/*------------------------------------------------------------------------------
    // Contact Form Overrides - Mr Zen Customisations
    //----------------------------------------------------------------------------*/

.sppb-form-group .sppb-form-control,
.sppb-form-control {
  @extend %zen-input;
}

/*------------------------------------------------------------------------------
    // Table Overrides - Mr Zen Customisations
    //----------------------------------------------------------------------------*/

.sppb-addon-table-main tbody tr {
  background: $light;
}

/*------------------------------------------------------------------------------
    // Remove SPPB 'z-index' CSS Class '.zen-remove-zindex'
    //----------------------------------------------------------------------------*/

#sp-page-builder .page-content .sppb-section.zen-remove-zindex,
.mod-sppagebuilder .sppb-section.zen-remove-zindex,
#sp-page-builder .sppb-section.zen-remove-zindex {
  z-index: auto;
}

.zen-remove-zindex .sppb-column-addons,
.zen-remove-zindex .sppb-column {
  z-index: auto;
}

/*------------------------------------------------------------------------------
    // Blog Flash SBPB Adjustments
    //----------------------------------------------------------------------------*/

.sppb-addon-article {
  @extend %zen-article;

  position: absolute;
  inset: 0;
}

.sppb-article-img-wrap {
  @extend %zen-article__image;
}

.sppb-article-info-wrap {
  @extend %zen-article__content;

  text-shadow: 0 0 5px rgba(0, 0, 0, 100%);
}

.sppb-readmore {
  @extend %zen-btn;
}

.sppb-addon-articles .sppb-col-sm-4 {
  @include media-breakpoint-down(md) {
    margin-top: $m-xl;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

/*------------------------------------------------------------------------------
// Why Choose Us SBPB Adjustments
//----------------------------------------------------------------------------*/

.why-choose-us .sppb-row {
  align-items: flex-end;

  @include media-breakpoint-down(md) {
    align-items: center;
  }

  .sppb-addon.sppb-addon-text-block {
    strong {
      font-size: 16px;
    }
  }
}

.hero-text .sppb-addon-text-block p {
  font-size: 17px;
}

/*------------------------------------------------------------------------------
// Featurebox SBPB Adjustments
//----------------------------------------------------------------------------*/

.zen-cta--featurebox .sppb-addon.sppb-addon-text-block.square {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zen-cta--featurebox {
  overflow: hidden;

  .zen-cta__featurebox-image {
    transition: all .3s ease-in-out;
    position: relative;
  }

  .zen-cta__featurebox-image:after {
    transition: all .3s ease-in-out;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0%) 0%, rgba(0, 0, 0, 0%) 100%);
  }

  &:hover {
    .zen-cta__featurebox-image {
      transform-origin: center;
      overflow: hidden;
      transform: scale(1.05);
    }

    .zen-cta__featurebox-image:after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0%) 0, rgba(0, 0, 0, 10%) 100%);
    }
  }
}

.zen-cta--featurebox .sppb-addon-title {
  color: #FFF;
  font-family: $primary-font;
  font-weight: 700;
  font-size: 60px;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-shadow: 0 0 10px rgba(0, 0, 0, 60%);

  @include media-breakpoint-down(lg) {
    font-size: 48px;
  }

  &:after {
    @extend %fontello;

    content: "\e820";
    color: $base-white;
    border: 2px solid $base-white;
    border-radius: 50vw;
    padding: 5px;
    display: inline-block;
    margin-top: 15px;
    font-size: 32px;
  }
}

.zen-cta__featurebox-image .sppb-img-responsive {
  width: 100%;
}

/*------------------------------------------------------------------------------
// Timeline SBPB Adjustments
//----------------------------------------------------------------------------*/
.sppb-addon-timeline .sppb-addon-timeline-wrapper .timeline-movement .timeline-item .timeline-date {
  margin: 0 0 0 70px;
  font-size: 25px;
  font-weight: bold;
  color: var(--rk-primary-light);

  @include media-breakpoint-down(md) {
    margin: 10px 15px 0;
  }
}

.sppb-addon-timeline .sppb-addon-timeline-wrapper .timeline-movement.odd .timeline-item .timeline-date {
  margin: 0 70px 0 0;

  @include media-breakpoint-down(md) {
    margin: 10px 15px 0;
  }
}

.sppb-addon-timeline .sppb-addon-timeline-wrapper .timeline-badge {
  &:before {
    border: 6px solid #DF3E26;
  }

  &:after {
    border: 1px dashed #CBA682;
    width: 65px;
    background: none !important;
  }

  @include media-breakpoint-down(md) {
    height: 60px !important;
    top: 0;

    &:after {
      top: 29px !important;
      width: 25px;
    }
  }
}

.sppb-addon-timeline .sppb-addon-timeline-wrapper .timeline-movement .timeline-item .timeline-panel {
  border: none;

  &:before {
    display: none;
  }

  .title {
    margin: 10px 0;
  }

  .details {
    img {
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 0 !important;

    .title {
      margin: 10px 0 5px;
    }
  }
}

.zen-blog__webinar {
  .sppb-addon-image-layout-image {
    overflow: hidden;
  }

  .sppb-addon-image-layouts .sppb-image-layout-title {
    font-size: 22px;
  }

  .sppb-addon-image-layout-content a {
    border: 1px solid var(--rk-primary);
    background: var(--rk-primary);
  }
}
