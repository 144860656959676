/*==============================================================================
// File:        _mapbox.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Mapbox style overrides - SASS
//============================================================================*/

/*==============================================================================
// Leaflet Container
//============================================================================*/
.mapboxgl-map {
  height: 700px;
}